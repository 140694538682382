import React from "react";
import styles from "./PageLayout.module.scss";

const TestModeOverlay = () => {
  return (
    <div className={styles.testModeOverlay}>
      <div className={styles.testModeOverlayContent}>Test Mode</div>
    </div>
  );
};

export default TestModeOverlay;
