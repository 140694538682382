import React, { useEffect, useState } from "react";
import { AuthenticationGuard, PageLayout } from "./components";
import { Route, Routes, useLocation } from "react-router-dom";
import { Callback } from "./pages/Callback";

import { ProductSale } from "./pages/Sales/ProductSale";
import { Checkout } from "./pages/Sales/Checkout";
import { CheckoutSuccess } from "./pages/Sales/CheckoutSuccess";
import { Creator } from "./pages/Sales/Creator";
import { VerifiedEmail } from "./pages/VerifiedEmail";
import { RefundPolicy } from "./pages/Sales/RefundPolicy";
import Invoice from "./pages/Order/Invoice";

import { AddWebhook } from "./pages/Developers/routes/Webhooks";
import SubscriptionPortal from "./pages/Sales/SubscriptionPortal";
import { SwitchInput } from "./components";

// http://localhost:4040/checkout?subscriptionId=67c3a226da3a5e35d33543c5

const App: React.FC = () => {
  const location = useLocation();
  const [isTestingMode, setIsTestingMode] = useState(false);
  const currentSubdomain = window.location.hostname.split(".")[0];

  const appRoutes = (
    <>
      <Route path="/callback" element={<Callback />} />
      <Route path="/invoice/:id" element={<Invoice />} />
      <Route path="/verify" element={<VerifiedEmail />} />
      <Route path="/developers/webhooks/add" element={<AddWebhook />} />
      <Route path="/developers/webhooks/:id" element={<AddWebhook />} />
      <Route
        path="/*"
        element={<AuthenticationGuard component={PageLayout} />}
      />
    </>
  );

  const condition = isTestingMode
    ? false
    : process.env.REACT_APP_ENV !== "production";

  useEffect(() => {
    // @ts-ignore
    window.Intercom("update");
  }, [location]);

  useEffect(() => {
    const testingMode = localStorage.getItem("testingMode");
    if (testingMode) {
      setIsTestingMode(testingMode === "true");
    }
  }, []);

  // Check if the current hostname is localhost
  const isLocalhost = window.location.hostname === "localhost";

  return (
    <>
      <Routes>
        {condition ? (
          appRoutes
        ) : currentSubdomain === "app" ? (
          appRoutes
        ) : (
          <>
            <Route path="/:id" element={<ProductSale />} />
            <Route
              path="/checkout/:clientSecret/:paymentIntentId"
              element={<Checkout />}
            />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/refund-policy/:prodId" element={<RefundPolicy />} />
            <Route path="/checkout/success" element={<CheckoutSuccess />} />
            <Route
              path="/portal/:subscriptionId/:subscriptionCustomerId"
              element={<SubscriptionPortal />}
            />
            <Route path="/" element={<Creator />} />
          </>
        )}
      </Routes>
      {isLocalhost && ( // Conditionally render the toggle
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Background color
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <label htmlFor="testing-mode">Testing Mode</label>
          <SwitchInput
            checked={isTestingMode}
            onChange={() => {
              localStorage.setItem("testingMode", (!isTestingMode).toString());
              setIsTestingMode(!isTestingMode);
            }}
            id="testing-mode"
            name="testing-mode"
          />
        </div>
      )}
    </>
  );
};

export default App;
