import React, { useState, useEffect, useRef, useMemo } from "react";
import styles from "./Sidebar.module.scss";
import {
  Home,
  Users,
  ShoppingCart,
  Box,
  DollarSign,
  MessageSquare,
  ArrowUpCircle,
  Settings,
  LogOut,
  HelpCircle,
  FolderPlus,
  Code,
  ExternalLink,
  CreditCard,
} from "react-feather";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

import { MdOutlineMailOutline, MdOutlineMoreVert } from "react-icons/md";
import "remixicon/fonts/remixicon.css";
import { Button } from "../Button";
import Axios from "src/services/api";
import {
  BsBank,
  BsLightningChargeFill,
  BsPatchCheckFill,
} from "react-icons/bs";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import toast from "react-hot-toast";
import { TbDiscount2 } from "react-icons/tb";
import { makeCdnUrl } from "src/services/cdn";
import { SwitchInput } from "../SwitchInput";
import { BiLaptop } from "react-icons/bi";
import { RiProfileLine } from "react-icons/ri";

const Sidebar = ({
  sidebarOpen,
  setSidebarOpen,
  toggleTestMode,
  isLoading,
  isTestMode,
}: any) => {
  const { user, logout } = useAuth0();

  const [open, setOpen] = useState(false);
  const [boughtProducts, setBoughtProducts] = useState<any>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [stripeAccountLoading, setStripeAccountLoading] = useState(true);
  const [stripeAccount, setStripeAccount] = useState<any>(null);

  useEffect(() => {
    const regex = /^\/products\/[0-9a-zA-Z]+$/;
    if (regex.test(location.pathname)) {
      setSidebarOpen(false);
    }
  }, [location.pathname]);

  const isActive = (route: string) => {
    const isDashboard = route === "/" && location.pathname === "/";
    if (isDashboard) {
      return location.pathname === route;
    } else {
      if (route === "/") {
        return false;
      }
      return location.pathname.includes(route);
    }
  };

  const ref = useRef<HTMLDivElement | null>(null);

  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    const fetchStripeAccount = async () => {
      try {
        setStripeAccountLoading(true);
        const response = await Axios.get("/users/stripe-account", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setStripeAccount(response.data);
        localStorage.setItem("stripeAccount", JSON.stringify(response.data));
      } catch (err) {
        console.error("Failed to fetch stripe account data:", err);
        // If API fails, try to load from localStorage as fallback
        const getLocalStorage = localStorage.getItem("stripeAccount");
        if (getLocalStorage) {
          setStripeAccount(JSON.parse(getLocalStorage));
        }
      } finally {
        setStripeAccountLoading(false);
      }
    };

    fetchStripeAccount();
  }, [location.pathname]);

  useEffect(() => {
    Axios.get("/bought-products", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((res) => {
      setBoughtProducts(res.data);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   // watch for screen size changes
  //   window.addEventListener("resize", () => {
  //     if (sidebarOpen) {
  //       setSidebarOpen(window.innerWidth > 1200);
  //     }
  //   });
  // }, [window.innerWidth]);

  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  useEffect(() => {
    if (me?.navbarClosed) {
      setSidebarOpen(false);
    }
  }, [me]);

  if (!user || !me) {
    return null;
  }

  const primaryMenuItems = [
    {
      name: "Dashboard",
      icon: (size = 18) => <Home className={styles.icon} size={size} />,
      to: "/",
    },
    {
      name: "Emails",
      icon: (size = 18) => (
        <MdOutlineMailOutline className={styles.icon} size={size} />
      ),
      to: "/emails",
      soon: true,
    },
    {
      name: "Customers",
      icon: (size = 18) => <Users className={styles.icon} size={size} />,
      to: "/customers",
    },
    {
      name: "Orders",
      icon: (size = 18) => <ShoppingCart className={styles.icon} size={size} />,
      to: "/orders",
    },
    {
      name: "Products",
      icon: (size = 18) => <Box className={styles.icon} size={size} />,
      to: "/products",
      new: true,
    },
    {
      name: "Discounts",
      icon: (size = 18) => <TbDiscount2 className={styles.icon} size={size} />,
      to: "/discounts",
    },
    {
      name: "Upsells",
      icon: (size = 18) => (
        <ArrowUpCircle className={styles.icon} size={size} />
      ),
      to: "/upsells",
    },
    {
      name: "Reviews",
      icon: (size = 18) => (
        <MessageSquare className={styles.icon} size={size} />
      ),
      to: "/reviews",
    },
    {
      name: "Payouts",
      icon: (size = 18) => <DollarSign className={styles.icon} size={size} />,
      to: "/payouts",
    },
    {
      name: "Refund policies",
      icon: (size = 18) => (
        <HiOutlineReceiptRefund className={styles.icon} size={size} />
      ),
      to: "/refunds",
    },
    {
      name: "Your page",
      icon: (size = 18) => (
        <RiProfileLine className={styles.icon} size={size} />
      ),
      to: `https://${me.subdomain}.pocketsflow.com`,
      external: true,
    },
  ];

  // const secondaryMenuItems = [
  //   {
  //     name: "Affiliates",
  //     icon: <UserCheck className={styles.icon} size={18} />,
  //     to: "/affiliates",
  //     soon: true,
  //   },
  //   {
  //     name: "Affiliated Products",
  //     icon: <Package className={styles.icon} size={18} />,
  //     to: "/affiliated-products",
  //     soon: true,
  //   },
  // ];

  const secondaryMenuItems = [
    {
      name: "Bought Products",
      icon: (size = 18) => <FolderPlus className={styles.icon} size={size} />,
      to: "/bought-products",
    },
    {
      name: "Developers",
      icon: (size = 18) => <Code className={styles.icon} size={size} />,
      to: "/developers",
    },
    {
      name: "Help Center",
      icon: (size = 18) => <HelpCircle className={styles.icon} size={size} />,
      to: "https://help.pocketsflow.com",
      external: true,
    },
  ];

  const thirdMenuItems = [
    {
      name: "Testing cards",
      icon: (size = 18) => <CreditCard className={styles.icon} size={size} />,
      to: "https://help.pocketsflow.com",
      external: true,
    },
  ];
  const hasBankAccount =
    stripeAccount && stripeAccount?.external_accounts?.data?.length > 0;
  const hasPayPal = !!me?.merchantId && !!me?.merchantIdInPayPal;

  // https://connect.stripe.com/express/acct_1QwXFZRRxcUl9Vxm/EULlitIJ3J7G

  const hasAlerts =
    stripeAccount?.requirements?.currently_due?.length > 0 ||
    stripeAccount?.requirements?.past_due?.length > 0 ||
    stripeAccount?.requirements?.eventually_due?.length > 0;

  const hasSeenSubscriptions = me?.hasSeenSubscriptions || false;

  const connectStripe = async () => {
    try {
      navigate("/profile/payments");
    } catch (error) {
      console.log(error);
    }
  };

  const createLogin = async () => {
    try {
      toast.promise(
        Axios.get("/users/create-login-link").then((response) => {
          if (response.data.url) {
            // window.open(response.data.url, "_blank");
            const a = document.createElement("a");
            a.href = response.data.url;
            a.rel = "noopener noreferrer";
            a.target = "_blank";
            a.click();
          } else {
            try {
              throw new Error("Failed to create login link.");
            } catch (error) {}
          }
        }),
        {
          loading: "Loading...",
          success: "Loaded!",
          error: "Error while loading link.",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  return sidebarOpen ? (
    <div className={styles.sidebar}>
      <Link to="/">
        <div className={styles.logo}>
          {/* Pocketsflow */}
          <img
            className={styles.logoImageText}
            src={process.env.PUBLIC_URL + "/pf-logo-text.svg"}
            alt="Pocketsflow"
          />
          {/* <div
            className={styles.arrow}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              // Update sidebar state immediately to avoid flicker
              requestAnimationFrame(() => {
                setSidebarOpen(false);
              });

              // Update user preferences in the backend
              Axios.post(
                "/users/update",
                {
                  navbarClosed: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              )
                .then((res) => {
                  localStorage.setItem("me", JSON.stringify(res.data));
                })
                .catch((err) => {
                  console.error("Failed to update user preferences:", err);
                });
            }}
          >
            <MdChevronLeft className={styles.chevronLeft} size={20} />
            <MdChevronRight className={styles.chevronRight} size={20} />
          </div> */}
        </div>
      </Link>
      <div className={styles.divider}></div>
      <div className={styles.sidebarLinks}>
        <div
          className={`${styles.menuItem} ${
            isTestMode && styles.activeTestModeItem
          } ${styles.testMode}`}
          onMouseUp={toggleTestMode}
        >
          <BiLaptop className={styles.icon} size={18} />
          <div className={styles.testModeText}>
            {isLoading ? "Loading..." : "Test mode"}
          </div>
          <div className={styles.testModeSwitch}>
            <SwitchInput
              id="testMode"
              name="testMode"
              checked={isTestMode}
              onChange={(e) => e}
              isSmall
            />
          </div>
        </div>
        {/* {!me?.hideGuide && (
          <Link
            className={`${styles.menuItem} ${
              isActive("/guide") && styles.activeItem
            } ${styles.guide}`}
            to="/guide"
          >
            <BsPatchCheckFill className={styles.icon} size={18} />
            Guided Setup
          </Link>
        )} */}
        {primaryMenuItems.map((item, index) => (
          <Link
            key={index}
            className={`${styles.menuItem} ${
              isActive(item.to) && styles.activeItem
            } ${item.soon && styles.comingSoon} ${item.new && styles.new}`}
            to={item.to}
            target={item.external ? "_blank" : undefined}
          >
            <div className={styles.iconContainer}>{item.icon()}</div>
            <span>{item.name}</span>
            {item.soon && <div className={styles.comingSoonText}>Soon</div>}
            {!hasSeenSubscriptions && (
              <div className={styles.newText}>
                <div className={styles.newDot}>
                  <div className={styles.newDotInner} />
                </div>
              </div>
            )}
            {item.external && (
              <ExternalLink
                className={`${styles.icon} ${styles.externalIcon}`}
                size={14}
              />
            )}
          </Link>
        ))}
        <br></br>
        <div className={styles.divider}></div>
        {secondaryMenuItems.map((item, index) => (
          <Link
            key={index}
            className={`${styles.menuItem} ${
              isActive(item.to) && styles.activeItem
            }`}
            to={item.to}
            target={item.external ? "_blank" : undefined}
          >
            {item.icon()}
            {item.name}
            {item.external && (
              <ExternalLink
                className={`${styles.icon} ${styles.externalIcon}`}
                size={14}
              />
            )}
          </Link>
        ))}
        <br />
        <br />
        <div className={styles.alertsContainer}>
          {stripeAccountLoading ? (
            ""
          ) : (
            <>
              {me?.indiaBusinessAwaitingApproval && (
                <div
                  className={`${styles.stripeAlert} ${styles.pendingApproval}`}
                  onClick={() => {
                    window.open("mailto:chain@pocketsflow.com", "_blank");
                  }}
                >
                  <div className={styles.stripeAlertText}>
                    <div className={styles.stripeAlertTitle}>
                      Business verification pending
                    </div>
                    <div className={styles.stripeAlertDescription}>
                      Your business details are awaiting approval. We'll notify
                      you within 24 hours.
                    </div>
                    <Button variant="danger" text="Contact us" />
                  </div>
                </div>
              )}
              {!hasBankAccount &&
                !hasPayPal &&
                !me?.indiaBusinessAwaitingApproval && (
                  <div
                    className={styles.stripeAlert + " " + styles.warning}
                    onClick={connectStripe}
                  >
                    <div className={styles.stripeAlertText}>
                      <div className={styles.stripeAlertTitle}>
                        Connect a payment gateway
                      </div>
                      <div className={styles.stripeAlertDescription}>
                        You need to connect either a Bank account or PayPal to
                        receive payments, and publish your products to start
                        selling.
                      </div>
                    </div>
                    <Button variant="danger" text="Connect a gateway" />
                  </div>
                )}
              {/* {hasBankAccount && hasAlerts && (
                <div
                  className={styles.stripeAlert + " " + styles.warning}
                  onClick={createLogin}
                >
                  <div className={styles.stripeAlertText}>
                    <div className={styles.stripeAlertTitle}>
                      Verification required
                    </div>
                    <div className={styles.stripeAlertDescription}>
                      Verification is needed for your bank account to continue
                      receiving payments securely and ensure your finances are
                      secure.
                    </div>
                  </div>
                  <Button variant="danger" text="Verify your account" />
                </div>
              )} */}
            </>
          )}
          {/* {!user?.email_verified && (
          <div className={styles.stripeAlert}>
            <div className={styles.stripeAlertText}>
              <div className={styles.stripeAlertTitle}>
                Verify your email address
              </div>
              <div className={styles.stripeAlertDescription}>
                You need to verify your email address to prove that you own it and
                start selling.
              </div>
            </div>
            <Button
              text="Connect a Bank Account"
              onClick={() => {
                navigate("/profile/payments");
              }}
            />
          </div>
        )} */}
        </div>
      </div>
      <div
        className={styles.profileContainer}
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        {open && (
          <span className={`${styles.profile}`} ref={ref}>
            <Link className={styles.profileLink} to="/profile">
              <Settings className={styles.icon} size={18} />

              <div>Settings</div>
            </Link>
            <div className={styles.divider}></div>
            <Link
              className={styles.profileLink}
              to="https://help.pocketsflow.com"
              target="_blank"
            >
              <HelpCircle className={styles.icon} size={18} />
              <div>Help Center</div>
            </Link>
            <div className={styles.divider}></div>
            <div
              className={styles.profileLink}
              onClick={() => {
                localStorage.removeItem("accessToken");
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }}
            >
              <LogOut className={styles.icon} size={18} />
              <div>Logout</div>
            </div>
          </span>
        )}
      </div>
      <div className={styles.userContainer} onClick={() => setOpen(!open)}>
        <div className={styles.userImage}>
          <img src={makeCdnUrl(me.picture)} alt="User" />
        </div>
        <span className={styles.userName}>
          {me.firstName || me.name} {me.lastName}
        </span>
      </div>
    </div>
  ) : (
    <div className={styles.sidebar + " " + styles.closedSidebar}>
      <div className={styles.hoverer} />
      <Link to="/">
        <div className={styles.logo}>
          <img
            className={styles.logoImage}
            src={process.env.PUBLIC_URL + "/pf-logo.svg"}
            alt="Pocketsflow"
          />
          {/* <div
            className={styles.arrow}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              // Update sidebar state immediately to avoid flicker
              requestAnimationFrame(() => {
                setSidebarOpen(true);
              });

              // Update user preferences in the backend
              Axios.post(
                "/users/update",
                {
                  navbarClosed: false,
                },
                {
                  headers: {
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              )
                .then((res) => {
                  localStorage.setItem("me", JSON.stringify(res.data));
                })
                .catch((err) => {
                  console.error("Failed to update user preferences:", err);
                });
            }}
          >
            <MdChevronLeft className={styles.chevronLeft} size={20} />
            <MdChevronRight className={styles.chevronRight} size={20} />
          </div> */}
        </div>
      </Link>
      <div className={styles.divider}></div>
      <div className={styles.sidebarLinks}>
        <div
          className={`${styles.menuItem} ${
            isTestMode && styles.activeTestModeItem
          } ${styles.testMode}`}
          onMouseUp={toggleTestMode}
        >
          <div className={styles.testModeSwitch}>
            <SwitchInput
              id="testMode"
              name="testMode"
              checked={isTestMode}
              onChange={(e) => e}
              isSmall
            />
          </div>
        </div>
        {/* {!me?.hideGuide && (
          <Link
            className={`${styles.menuItem} ${
              isActive("/guide") && styles.activeItem
            } ${styles.guide}`}
            to="/guide"
          >
            <BsPatchCheckFill className={styles.icon} size={24} />
          </Link>
        )} */}
        {primaryMenuItems.map((item, index) => (
          <LinkItem key={index} item={item} index={index} isActive={isActive} />
        ))}
        <div className={styles.divider}></div>
        {secondaryMenuItems.map((item, index) => (
          <LinkItem item={item} index={index} isActive={isActive} />
        ))}
        <br />
        <br />
        <div className={styles.alertsContainer}>
          {stripeAccountLoading ? (
            <div className={styles.stripeAlert + " " + styles.loading}>
              <div className={styles.stripeAlertText}>
                <div className={styles.stripeAlertTitle}>Loading...</div>
              </div>
            </div>
          ) : (
            <>
              {!hasBankAccount && !hasPayPal && (
                <div
                  className={styles.stripeAlert + " " + styles.warning}
                  onClick={connectStripe}
                >
                  <BsBank size={18} className={styles.bankIcon} />
                </div>
              )}
              {hasAlerts && hasBankAccount && (
                <div
                  className={styles.stripeAlert + " " + styles.warning}
                  onClick={createLogin}
                >
                  <BsLightningChargeFill
                    size={18}
                    className={styles.bankIcon}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.divider}></div>
      {open && (
        <span className={`${styles.profile}`} ref={ref}>
          <Link className={styles.profileLink} to="/profile">
            <Settings className={styles.icon} size={18} />
            <div>Settings</div>
          </Link>
          <div className={styles.divider}></div>
          <Link
            className={styles.profileLink}
            to="https://help.pocketsflow.com"
            target="_blank"
          >
            <HelpCircle className={styles.icon} size={18} />
            <div>Help Center</div>
          </Link>
          <div className={styles.divider}></div>
          <div
            className={styles.profileLink}
            onClick={() => {
              localStorage.removeItem("accessToken");
              logout({
                logoutParams: {
                  returnTo: window.location.origin,
                },
              });
            }}
          >
            <LogOut className={styles.icon} size={18} />
            <div>Logout</div>
          </div>
        </span>
      )}
      <div
        className={styles.userContainer}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}
      >
        <div className={styles.userImage}>
          <img src={me.picture} alt="User" />
        </div>
      </div>
      {/* </Link> */}
    </div>
  );
};

const LinkItem = ({ item, index, isActive }: any) => {
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: "right",
    trigger: "hover",
    offset: [0, 10],
  });
  return (
    <>
      <Link
        key={index}
        className={`${styles.menuItem} ${
          isActive(item.to) && styles.activeItem
        } ${item.soon && styles.comingSoon} ${item.new && styles.new}`}
        to={item.to}
        ref={setTriggerRef}
      >
        {item.icon()}
      </Link>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
            style: { fontSize: "1.6rem", padding: "0.5rem 1rem" },
          })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })} />
          {item.name}
        </div>
      )}
    </>
  );
};

export default Sidebar;
