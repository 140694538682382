import React, { useEffect, useMemo, useState } from "react";
import styles from "../../Subscription.module.scss";
import {
  CheckInput,
  FormGroup,
  Select,
  SwitchInput,
  TextArea,
  TextInput,
} from "src/components";
import TogglableArea from "src/pages/Product2/steps/DefineProduct/TogglableArea";
import TogglableTabs from "src/pages/Product2/steps/DefineProduct/ToggleTabs";
import { CheckCircle, Clipboard } from "react-feather";
import toast from "react-hot-toast";

const PortalSubscription = ({
  subscription,
  errors,
  setSubscription,
}: {
  subscription: any;
  setSubscription: React.Dispatch<React.SetStateAction<any>>;
  errors: any;
}) => {
  const [copied, setCopied] = useState(false);
  const [subdomain, setSubdomain] = useState("");
  const onChange = (key: string, value: string | boolean) => {
    setSubscription({ ...subscription, [key]: value });
  };

  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  // Reset copied state after 2s
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied]);

  useEffect(() => {
    setSubdomain(me?.subdomain || "");
  }, [me]);

  const portalUrl = `https://${subdomain}.pocketsflow.com/portal/{subscriptionId}/{subscriptionCustomerId}`;

  return (
    <div className={styles.step}>
      <h1>Setup the subscription portal</h1>
      <p className={styles.desc}>
        {/* Get portal URL / show IDs in webhook data / Setup redirect back URL */}
        Get subscription portal link and learn how to set it up correctly with
        IDs and a redirect URL
      </p>
      <div className={styles.form}>
        <TogglableArea
          title="Subscription billing portal"
          subTitle="The subscription billing portal is where your customers can manage their subscription, change payment methods, and download invoices."
        >
          <div className={styles.portalUrlContainer}>
            <FormGroup label="Subscription Portal URL">
              <TextInput
                value={portalUrl}
                name="portalUrl"
                onChange={() => {}}
                disabled
              />
            </FormGroup>
            <div
              className={styles.copyIcon}
              onClick={() => {
                navigator.clipboard.writeText(portalUrl || "");
                setCopied(true);
                toast.success("Subscription portal URL copied");
              }}
            >
              {copied ? <CheckCircle size={16} /> : <Clipboard size={16} />}
            </div>
          </div>
          <div className={styles.howToSection}>
            <h3>Important note:</h3>
            <p>
              For each of your subscribed customers, you will get{" "}
              <code>subscriptionId</code> and{" "}
              <code>subscriptionCustomerId</code> values in the webhook{" "}
              <code>customer.subscription.updated</code> when the status is
              active.
              <br />
              <br />
              <li>
                <code>subscriptionId</code> is <code>subscription.id</code>
              </li>
              <li>
                <code>subscriptionCustomerId</code> is{" "}
                <code>subscriptionCustomer.id</code>
              </li>
              <br />
              Store them in your database because that's how you identify
              subscriptions.
            </p>
            {/* show code block */}
          </div>
        </TogglableArea>
        <TogglableArea
          title="Subscription portal redirect URL"
          subTitle="This URL is where your customers will be redirected after they close the subscription portal. It must point to your app."
        >
          <FormGroup
            label="Subscription portal redirect URL"
            required
            error={errors.redirectBackUrl}
          >
            <TextInput
              onChange={(e: any) => onChange("redirectBackUrl", e.target.value)}
              value={subscription.redirectBackUrl}
              error={errors.redirectBackUrl}
              name="redirectBackUrl"
              placeholder="e.g. https://pocketsflow.com"
            />
          </FormGroup>
        </TogglableArea>
      </div>
    </div>
  );
};

export default PortalSubscription;
