import React, { useEffect, useState } from "react";
import styles from "./Guide.module.scss";
import { Button, EmptyState, PageTitle } from "src/components";
import { Check } from "react-feather";
import { BsPatchCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Axios from "src/services/api";
import { toast } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";

// 1- connect bank account
// 2- create your first product
// 3- get your first sale

const Guide: React.FC = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hiding, setHiding] = useState(false);
  const [me, setMe] = useState<any>(
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!)
  );

  const [stripeAccount, setStripeAccount] = React.useState<any>(null);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      setStripeAccount(res.data);
    });
  }, []);

  const hasBankAccount =
    stripeAccount && stripeAccount?.external_accounts?.data?.length > 0;
  const hasPayPal = !!me?.merchantId && !!me?.merchantIdInPayPal;

  useEffect(() => {
    setLoading(true);
    Axios.get("/users/me")
      .then((res) => {
        setMe(res.data);
        setLoading(false);
        localStorage.setItem("me", JSON.stringify(res.data));
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401 || err.response.status === 500) {
          localStorage.removeItem("accessToken");
          toast.error("You are not logged in.");
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        }
      });
  }, []);

  const [currentStep, setCurrentStep] = useState("");

  useEffect(() => {
    if (me.createdFirstProduct) {
      setCurrentStep("connectedBank");
      return;
    }

    if (!me.createdFirstProduct) {
      setCurrentStep("createdFirstProduct");
      return;
    }

    if (hasBankAccount || hasPayPal) {
      setCurrentStep("gotFirstSale");
      return;
    }
  }, [me, hasBankAccount, hasPayPal]);

  const steps = [
    {
      stepId: "createdFirstProduct",
      title: "Create your first product or subscription",
      subtitle:
        "Create your first product or subscription, choose a name, a pricing model, write copy, and pushblish it to start selling.",
      buttonText: "Create product",
      buttonOnClick: () => {
        navigate("/products");
      },
    },
    {
      stepId: "connectedBank",
      title: "Connect a payment gateway",
      subtitle:
        "You need to connect either your Bank account or PayPal to start receiving payments and withdraw your earnings.",
      buttonText: "Connect payment gateway",
      buttonOnClick: () => {
        navigate("/profile/payments");
      },
    },
    {
      stepId: "gotFirstSale",
      title: "Get your first sale",
      subtitle:
        "You're all set! Now you can start sharing your product with the world and get your first sale!",
    },
  ];

  return (
    <>
      <div className={styles.pageContainer}>
        <PageTitle>Guided setup</PageTitle>
        {loading ? (
          <EmptyState loading={loading} />
        ) : (
          <div className={styles.content}>
            <div className={styles.illustrationContainer}>
              <BsPatchCheck size={44} color="#FFF" />
            </div>
            <div className={styles.title}>Let's get you started!</div>
            <div className={styles.subTitle}>
              You're almost there! Let's get you started with a few steps and
              off you go with your first sale!
            </div>
            <div className={styles.stepsContainer}>
              {steps.map((step, index) => (
                <div
                  key={index}
                  className={
                    styles.step +
                    " " +
                    (currentStep !== step.stepId ? styles.notArrived : "") +
                    " " +
                    (me[step.stepId] ? styles.active : "")
                  }
                >
                  <div className={styles.checkContainer}>
                    <Check size={18} color="white" />
                  </div>
                  <div className={styles.textContainer}>
                    <div className={styles.stepTitle}>{step.title}</div>
                    {currentStep === step.stepId && (
                      <>
                        <div className={styles.stepSubTitle}>
                          {step.subtitle}
                        </div>
                        {step.buttonText && (
                          <Button
                            text={step.buttonText}
                            onClick={step.buttonOnClick}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {me.createdFirstProduct && (hasBankAccount || hasPayPal) && (
        <div className={styles.hideContainer}>
          <Button
            disabled={hiding}
            text={hiding ? "Loading..." : "Hide this guide"}
            onClick={() => {
              setHiding(true);
              Axios.post("/users/hide-guide").then((res) => {
                setMe(res.data);
                localStorage.setItem("me", JSON.stringify(res.data));
                window.location.href = "/";
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export default Guide;
