import { FiEdit, FiLayout, FiMail, FiShoppingBag } from "react-icons/fi";

import { ProductType } from "./Product.types";

export const defaultProduct: ProductType = {
  name: "",
  subtitle: "",
  price: 0,
  payWant: false,
  description: "",
  file: "",
  url: "",
  images: [],
  published: true,
  isFile: true,
  design: 1,
  hasProductPage: "unknown",
  colors: [
    {
      id: "title",
      name: "Title",
      value: "#000000",
    },
    {
      id: "buttons",
      name: "Buttons background",
      value: "#3447ef",
    },
    {
      id: "buttonsText",
      name: "Buttons text",
      value: "#FFFFFF",
    },
    {
      id: "background",
      name: "Background",
      value: "#FFFFFF",
    },
    {
      id: "text",
      name: "Text",
      value: "#000000",
    },
    {
      id: "borders",
      name: "Borders",
      value: "#cfd7df",
    },
    {
      id: "heroBackground",
      name: "Hero background",
      value: "#6772e5",
    },
    {
      id: "cardBackground",
      name: "Card background",
      value: "#FFFFFF",
    },
  ],
  ctaText: "buyForPrice",
  userId: "",
  showSales: true,
  showReviews: true,
  checkoutText: "",
  thankYouText: "",
  refundPolicy: null,
  hasCallbackUrl: false,
  callbackUrl: "",

  redirectUrl: undefined,

  hasFirstName: false,
  hasLastName: false,

  slug: "",
};

export const STEPS = [
  {
    id: "define",
    name: "Define your product",
    icon: <FiEdit size={18} />,
    description: "Add a product name, description, file or url, and price",
  },
  {
    id: "product-page",
    name: "Product page",
    icon: <FiLayout size={18} />,
    description: "Choose from templates and customize your product page.",
  },
  {
    id: "checkout",
    name: "Checkout",
    icon: <FiShoppingBag size={18} />,
    description: "Customize your checkout page to maximize conversion.",
  },
  {
    id: "email",
    name: "Success Emails",
    icon: <FiMail size={18} />,
    description: "Change the content and button of the email",
  },
];
