import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./app";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import "./styles/styles.css";
import { Toaster } from "react-hot-toast";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a5fd7b25603bc6a529cd12cd5c21ca11@o4505351069827072.ingest.us.sentry.io/4508891066400768",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/*",
    element: <App />,
  },
]);

root.render(
  <Auth0ProviderWithNavigate>
    <RouterProvider router={router} />
    <Toaster />
  </Auth0ProviderWithNavigate>
);
