import React, { useEffect, useState } from "react";
import styles from "../../Product.module.scss";
import { Button, FormGroup, Modal, TextArea, TextInput } from "src/components";
import TogglableArea from "./TogglableArea";
import TogglableTabs from "./ToggleTabs";
import { ProductType } from "../../Product.types";
import SingleUploader from "./SingleUploader";
import usePopups from "src/services/usePopups";
import { VariantModal } from "../../components";
import Axios from "src/services/api";
import toast from "react-hot-toast";
import { makeCdnUrl } from "src/services/cdn";
import { MdDeleteOutline, MdModeEditOutline } from "react-icons/md";
import { formatCurrency } from "src/services/currency";
import { VariantType } from "../../components/VariantModal/VariantModal.types";
import { Terminal } from "react-feather";
import { SwitchInput } from "src/components";

const DefineProduct = ({
  product,
  setProduct,

  // files:
  file,
  setFile,

  thumbnail,
  setThumbnail,

  variants,
  setVariants,

  errors,
  loadingFile,
  loadingThumbnail,
}: {
  product: ProductType;
  setProduct: React.Dispatch<React.SetStateAction<ProductType>>;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  id?: string;

  thumbnail: File | null;
  setThumbnail: React.Dispatch<React.SetStateAction<File | null>>;
  images: File[] | null;
  setImages: React.Dispatch<React.SetStateAction<File[] | null>>;

  variants: any[];
  setVariants: React.Dispatch<React.SetStateAction<any[]>>;

  errors: any;
  loadingFile?: boolean;
  loadingThumbnail?: boolean;
}) => {
  const { popups, togglePopup } = usePopups(["addVariant", "deleteVariant"]);
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [refreshVariants, setRefreshVariants] = useState(false);
  const [variantId, setVariantId] = useState<string | null>(null);
  const [variantArg, setVariantArg] = useState<VariantType | undefined>(
    undefined
  );
  const [prodType, setProdType] = useState<string>(
    product.isFile ? "file" : "url"
  );
  const [priceType, setPriceType] = useState<string>(
    !product._id
      ? "regular"
      : product.payWant
      ? "payWant"
      : product.price === 0
      ? "free"
      : "regular"
  );
  const [localLoadingFile, setLocalLoadingFile] = useState(false);
  const [localLoadingThumbnail, setLocalLoadingThumbnail] = useState(false);
  const [checkingSlug, setCheckingSlug] = useState(false);
  const [slugError, setSlugError] = useState<string | null>(null);
  const [slugSuccess, setSlugSuccess] = useState<boolean>(false);
  const onChange = (key: string, value: string | boolean) => {
    setProduct({ ...product, [key]: value });
  };

  const onTypeChange = (value: string) => {
    setProdType(value);
    setProduct({ ...product, isFile: value === "file" });
  };

  const onPriceChange = (value: string) => {
    setPriceType(value);
    if (value === "free") {
      setProduct({ ...product, price: 0, payWant: false });
    } else if (value === "payWant") {
      setProduct({
        ...product,
        price: 0,
        minPrice: 9.99,
        maxPrice: 29.99,
        payWant: true,
      });
    } else {
      setProduct({ ...product, price: 9.99, payWant: false });
    }
  };

  useEffect(() => {
    if (!product._id) {
      return;
    }

    setLoadingVariants(true);
    Axios.get(`/products/${product._id}/variants`)
      .then(async (res) => {
        setLoadingVariants(false);
        setVariants(res.data);
      })
      .catch((err) => {
        console.error(JSON.stringify(err));
      });
  }, [product._id, refreshVariants]);

  const onFileChange = (file: File | null) => {
    setFile(file);
  };

  const onThumbnailChange = (file: File | null) => {
    setThumbnail(file);
  };

  const onToggleAddVariant = () => {
    togglePopup("addVariant");
  };

  const onToggleDeleteVariant = () => {
    togglePopup("deleteVariant");
  };

  const [copiedId, setCopiedId] = useState(undefined);

  useEffect(() => {
    if (copiedId) {
      setTimeout(() => {
        setCopiedId(undefined);
      }, 2000);
    }
  }, [copiedId]);

  // Add debounced slug check function
  const checkSlugUniqueness = async (slug: string) => {
    if (!slug) {
      setSlugError(null);
      setSlugSuccess(false);
      return;
    }

    setSlugSuccess(false);
    setSlugError(null);
    try {
      const response = await Axios.get(
        `/products/${product?._id || "new"}/check-slug/${slug}`
      );
      if (
        response.data.exists &&
        (!product._id || response.data.productId !== product._id)
      ) {
        setSlugError("This slug is already taken");
        setSlugSuccess(false);
      } else {
        setSlugSuccess(true);
        setSlugError(null);
      }
    } catch (error) {
      console.error("Error checking slug:", error);
      setSlugError("Error checking slug availability");
    } finally {
      setCheckingSlug(false);
    }
  };

  // Create debounced version of the function
  const debouncedCheckSlug = React.useCallback(
    (() => {
      let timeoutId: NodeJS.Timeout;
      return (slug: string) => {
        setCheckingSlug(true);
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => checkSlugUniqueness(slug), 800);
      };
    })(),
    [product._id]
  );

  // Update the slug change handler
  const onSlugChange = (value: string) => {
    // Only allow lowercase letters, numbers, and hyphens
    const sanitizedSlug = value.toLowerCase().replace(/[^a-z0-9-]/g, "-");
    onChange("slug", sanitizedSlug);
    debouncedCheckSlug(sanitizedSlug);
  };
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);

  return (
    <div className={styles.step}>
      <h1>Define your product</h1>
      <p className={styles.desc}>
        Add product info, pricing structure, thumbnail and images.
      </p>
      <div className={styles.form}>
        <FormGroup label="Title" error={errors.name} required>
          <TextInput
            onChange={(e: any) => onChange("name", e.target.value)}
            value={product.name}
            name="name"
            placeholder="Enter product title"
            error={errors.name}
          />
        </FormGroup>
        <TogglableArea
          title="Product slug"
          subTitle="A URL-friendly identifier for your product. If you leave it blank, we will use the product's ID."
        >
          <FormGroup label="Slug" error={slugError || errors.slug}>
            <TextInput
              onChange={(e: any) => onSlugChange(e.target.value)}
              value={product.slug || ""}
              name="slug"
              placeholder="Enter product slug"
              error={slugError || errors.slug}
            />
          </FormGroup>
          {checkingSlug && (
            <div className={styles.checkingSlug + " " + styles.checking}>
              Checking uniqueness...
            </div>
          )}
          {slugSuccess && (
            <div className={styles.checkingSlug + " " + styles.success}>
              Slug is unique! 🎉
            </div>
          )}
          {slugError && (
            <div className={styles.checkingSlug + " " + styles.error}>
              Slug is not unique.
            </div>
          )}
        </TogglableArea>
        <div id="url">
          <TogglableArea
            title="Product type"
            subTitle="Choose the type of your product, whether as an automatic redirect URL or as a file, URL, or course."
            required
          >
            {
              <>
                <FormGroup label="Redirect to a callback URL?" inline reversed>
                  <SwitchInput
                    name="hasCallbackUrl"
                    checked={product.hasCallbackUrl || false}
                    onChange={(value) => {
                      onChange("hasCallbackUrl", value);
                    }}
                    id="hasCallbackUrl"
                  />
                </FormGroup>
                {product.hasCallbackUrl ? (
                  <div className={styles.usernameHolder}>
                    <FormGroup
                      label="Callback URL"
                      error={errors.callbackUrl}
                      required
                    >
                      <TextInput
                        value={product.callbackUrl || ""}
                        name="callbackUrl"
                        onChange={(e: any) =>
                          onChange("callbackUrl", e.target.value)
                        }
                        error={errors.callbackUrl}
                        placeholder="e.g. https://pocketsflow.com"
                      />
                    </FormGroup>
                    <div className={styles.suffix}>
                      {
                        "?productId={id}?productId={id}&buyerEmail={buyerEmail}&redirect_status=succeeded"
                      }
                    </div>
                  </div>
                ) : (
                  <>
                    <TogglableTabs
                      items={[
                        {
                          id: "file",
                          title: "File",
                          subTitle: "People will download your product",
                        },
                        {
                          id: "url",
                          title: "URL",
                          subTitle: "People will get access to your URL",
                        },
                        {
                          id: "course",
                          title: "Course",
                          subTitle:
                            "Host your course and let your students enroll",
                          soon: true,
                        },
                      ]}
                      onChange={(value: string) => onTypeChange(value)}
                      value={prodType}
                    />
                    {prodType === "file" ? (
                      <SingleUploader
                        onChange={onFileChange}
                        value={file}
                        text="Maximum file size: 200MB"
                        error={errors.file}
                        loading={loadingFile || localLoadingFile}
                      />
                    ) : (
                      <FormGroup label="URL" required error={errors.url}>
                        <TextInput
                          onChange={(e: any) => onChange("url", e.target.value)}
                          value={product.url}
                          error={errors.url}
                          name="url"
                          placeholder="Enter product URL"
                        />
                      </FormGroup>
                    )}
                  </>
                )}
              </>
            }
          </TogglableArea>
        </div>
        <div id="price">
          <TogglableArea
            title="Pricing"
            subTitle="Choose a pricing structure that's suitable for you and price your product accordingly."
          >
            <TogglableTabs
              items={[
                {
                  id: "regular",
                  title: "Regular price",
                  subTitle: "Charge a regular fee",
                },
                {
                  id: "payWant",
                  title: "Pay what you want",
                  subTitle: "People will set the price",
                },
                {
                  id: "free",
                  title: "Lead magnet",
                  subTitle: "Give it away for free",
                },
              ]}
              onChange={(value: string) => onPriceChange(value)}
              value={priceType}
            />
            {priceType === "regular" ? (
              <FormGroup label="Price" error={errors.price} required>
                <TextInput
                  onChange={(e: any) => onChange("price", e.target.value)}
                  value={product.price}
                  error={errors.price}
                  name="price"
                  placeholder="0"
                  isCurrency
                  type="number"
                />
              </FormGroup>
            ) : priceType === "payWant" ? (
              <div className={styles.formGrid}>
                <FormGroup
                  label="Minimum price"
                  error={errors.minPrice}
                  required
                >
                  <TextInput
                    onChange={(e: any) => onChange("minPrice", e.target.value)}
                    value={product.minPrice || 0}
                    error={errors.minPrice}
                    name="minPrice"
                    placeholder="0"
                    isCurrency
                    type="number"
                  />
                </FormGroup>
                <FormGroup label="Suggested price">
                  <TextInput
                    onChange={(e: any) => onChange("maxPrice", e.target.value)}
                    value={product.maxPrice || 0}
                    name="maxPrice"
                    placeholder="0"
                    isCurrency
                    type="number"
                  />
                </FormGroup>
              </div>
            ) : (
              <FormGroup label="Price">
                <TextInput
                  onChange={(e: any) => onChange("price", e.target.value)}
                  value={product.price}
                  name="price"
                  placeholder="0"
                  isCurrency
                  type="number"
                  disabled
                />
              </FormGroup>
            )}
          </TogglableArea>
        </div>

        <TogglableArea
          title="Thumbnail"
          subTitle="Upload a thumbnail of your product. Used in your profile page,
          checkout, and email."
        >
          <SingleUploader
            onChange={onThumbnailChange}
            value={thumbnail}
            text="Supports JPEG, PNG, GIF. Maximum file size: 4MB."
            type="image"
            loading={loadingThumbnail || localLoadingThumbnail}
          />
        </TogglableArea>

        <TogglableArea
          title="Product Variants"
          subTitle="Add variations of this product. Offer your customers different options at different prices, versions, formats, shapes, and more."
        >
          {loadingVariants ? (
            <>Loading variants...</>
          ) : (
            <div className={styles.variantsGrid}>
              {variants
                .map((variant: any) => {
                  return (
                    <div className={styles.variant}>
                      <img
                        onClick={() => {
                          setVariantId(variant._id);
                          setVariantArg(variant);
                          onToggleAddVariant();
                        }}
                        src={
                          variant.thumbnail
                            ? makeCdnUrl(variant.thumbnail)
                            : "https://dummyimage.com/1200&text=Product+Image"
                        }
                      />
                      <div
                        className={styles.variantContent}
                        onClick={() => {
                          setVariantId(variant._id);
                          setVariantArg(variant);
                          onToggleAddVariant();
                        }}
                      >
                        <div className={styles.variantTitle}>
                          {variant.name || "N/A"} —{" "}
                          <div className={styles.variantPrice}>
                            {formatCurrency(variant.price, me?.currency)}
                          </div>
                        </div>
                        <div
                          className={
                            styles.idContainerBig + " " + styles.inVariant
                          }
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div
                            className={styles.idContainer}
                            data-tooltip-id="subscription-id-tooltip"
                            data-tooltip-content={
                              copiedId === variant._id
                                ? "Copied!"
                                : "Click to copy"
                            }
                            onClick={() => {
                              navigator.clipboard.writeText(variant._id);
                              setCopiedId(variant._id);
                            }}
                          >
                            <div className={styles.idIcon}>
                              <Terminal size={9} />
                            </div>
                            <div className={styles.idText}>{variant._id}</div>
                          </div>
                          {copiedId === variant._id && (
                            <div className={styles.copiedId}>Copied!</div>
                          )}
                        </div>
                      </div>
                      <div className={styles.actions}>
                        <MdModeEditOutline
                          size={20}
                          color="#797b7e"
                          onClick={() => {
                            setVariantId(variant._id);
                            setVariantArg(variant);
                            onToggleAddVariant();
                          }}
                        />
                        <MdDeleteOutline
                          size={20}
                          color="#e54f4f"
                          onClick={() => {
                            onToggleDeleteVariant();
                            setVariantId(variant._id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })
                .reverse()}
            </div>
          )}
          <Button
            text="Add a variant"
            variant="secondary"
            onClick={onToggleAddVariant}
          />
        </TogglableArea>
        {/* 
        <TogglableArea
          title="Images"
          subTitle="Upload images of your product. They will be shown in your product page as carousel."
        >
          <MultipleUploader
            onChange={onImagesChange}
            value={images}
            text="Supports JPEG, PNG, GIF. Maximum file size: 4MB."
            type="image"
          />
        </TogglableArea> */}
        {/* 
        <TogglableArea
          title="FAQs"
          subTitle="Add frequently asked questions and answers to your product."
        >
          
        </TogglableArea> */}

        {popups.addVariant && (
          <VariantModal
            productId={product._id || ""}
            variantId={variantId || undefined}
            variantArg={variantArg}
            onClose={(variant?: VariantType) => {
              setVariantId(null);
              onToggleAddVariant();
              setRefreshVariants((prev) => !prev);
              setVariantArg(undefined);
              if (variant) {
                setVariants((prev) => [variant, ...prev]);
              }
            }}
          />
        )}

        {popups.deleteVariant && (
          <Modal
            onClose={() => {
              togglePopup("deleteVariant");
              setVariantId(null);
            }}
            title="Delete Variant"
            footerRightButton1={{
              label: "Cancel",
              onClick: () => {
                setRefreshVariants((prev) => !prev);
                togglePopup("deleteVariant");
                setVariantId(null);
              },
            }}
            footerRightButton2={{
              variant: "danger",
              label: "Delete",
              onClick: () => {
                Axios.delete("/products/variants/" + variantId)
                  .then(() => {
                    togglePopup("deleteVariant");
                    setRefreshVariants((prev) => !prev);
                    toast.success("Variant deleted!");
                  })
                  .catch((error) => {
                    console.log(error);
                    togglePopup("deleteVariant");
                    setRefreshVariants((prev) => !prev);
                    toast.error("Failed to delete variant");
                  });
              },
            }}
          >
            <div className={styles.deleteModalText}>
              Are you sure you want to delete this variant?
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default DefineProduct;
