import React from "react";
import styles from "./Onboarding.module.scss";
import { FormGroup, TextArea, TextInput } from "src/components";

const HearAboutUs = ({ userState, setUserState }: any) => {
  const onChange = (key: string, value: string) => {
    setUserState({ ...userState, [key]: value });
  };
  return (
    <div className={styles.step}>
      <div className={styles.title}>How did you hear about us?</div>
      <div className={styles.subTitle}>
        This is optional but we would love to hear how you found out about us!
        It will be very helpful for us.
      </div>
      <div className={styles.content}>
        <TextArea
          value={userState.hearAboutUs}
          name="hearAboutUs"
          onChange={(e: any) => onChange("hearAboutUs", e.target.value)}
          placeholder="e.g. I saw you on Twitter."
        />
        {/* <FormGroup label="Invite code">
          <TextInput
            value={userState.inviteCode}
            name="inviteCode"
            onChange={(e: any) => onChange("inviteCode", e.target.value)}
            placeholder="e.g. T$2IJ43"
          />
        </FormGroup> */}
      </div>
    </div>
  );
};

export default HearAboutUs;
