import { FiEdit, FiShoppingBag, FiSidebar } from "react-icons/fi";

export const STEPS = [
  {
    id: "define",
    name: "Define your subscription",
    icon: <FiEdit size={18} />,
    description: "Add a subscription name, description, file or url, and price",
  },
  {
    id: "checkout",
    name: "Checkout",
    icon: <FiShoppingBag size={18} />,
    description: "Customize your checkout page to maximize conversion.",
  },
  {
    id: "portal",
    name: "Subscription Portal",
    icon: <FiSidebar size={18} />,
    description: "Copy and use the subscription portal for your customers.",
  },
];

export const defaultSubscription = {
  name: "",
  subtitle: "",
  price: 0,
  description: "",
  file: "",
  isFile: true,
  url: "",
  thumbnail: "",
  images: [],
  published: true,
  checkoutText: "",
  thankYouText: "",
  refundPolicy: "",
  userId: "",
  frequency: "monthly",
  callbackUrl: "",
  hasCallbackUrl: false,
  redirectBackUrl: "",
  // Set startDate to the end of the day
  // startDate: new Date(new Date().setHours(22, 59, 59, 999)),
  // endDate: undefined,
  // isForever: true,
  trialPeriod: 0,
  hasFirstName: false,
  hasLastName: false,
};
