import React, { useMemo } from "react";
import style from "./TextInput.module.scss";

interface TextInputProps {
  id?: string;
  name: string;
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  isCurrency?: boolean;
  isPercentage?: boolean;
  disabled?: boolean;
  currency?: string;
  isPayWant?: boolean;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  isCta?: boolean;
  error?: string;
  rightIcon?: React.ReactNode;
  onClick?: any;
  autoComplete?: string;
  inCheckout?: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  value,
  onChange,
  placeholder,
  type = "text",
  isCurrency,
  isPercentage,
  disabled,
  currency,
  isPayWant,
  backgroundColor,
  textColor,
  borderColor,
  isCta,
  error,
  rightIcon,
  onClick,
  autoComplete,
  inCheckout,
}) => {
  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")]
  );

  return (
    <div
      className={`${
        isCurrency || isPercentage ? style.currencyContainer : ""
      } ${isPayWant ? style.isPayWant : ""} ${isCta ? style.isCta : ""} 
      } ${style.inputContainer} ${inCheckout ? style.inCheckout : ""}`}
      onClick={onClick}
    >
      <input
        id={id}
        className={
          style.textInput +
          (disabled ? " " + style.disabled : "") +
          (error ? " " + style.isError : "")
        }
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        autoComplete={autoComplete}
        // style={
        //   isPayWant
        //     ? {
        //         background: backgroundColor,
        //         color: textColor,
        //         border: `1px solid ${borderColor}`,
        //       }
        //     : {}
        // }
      />
      {isCurrency && (
        <span
          className={style.currencySymbol}
          // style={
          //   isPayWant
          //     ? {
          //         background: backgroundColor,
          //         color: textColor,
          //       }
          //     : {}
          // }
        >
          {currency || me.currency}
        </span>
      )}
      {isPercentage && <span className={style.currencySymbol}>%</span>}
      {rightIcon && <span className={style.rightIcon}>{rightIcon}</span>}
    </div>
  );
};

export default TextInput;
