import React, { useEffect, useState } from "react";
import styles from "./Onboarding.module.scss";

const Help = ({ userState, setUserState }: any) => {
  const onChange = (key: string, value: string) => {
    setUserState({ ...userState, [key]: value });
  };

  // based on userState.level, we can show different content here

  const content: any = {
    newbie:
      "That's enough for now. Let's get you started! I heard you're a newbie, so here is a video from me, I'll help you get started.",
    startedRecently:
      "That's enough for now. Let's get you started! I heard you've started recently, so here is a video from me, I'll help you get started and show you around the platform.",
    experienced:
      "That's enough for now. Let's get you started! I heard you're experienced, so here is a video from me. I'll show you around Pocketsflow.",
  };

  const video: any = {
    newbie: "https://pf-vids.s3.amazonaws.com/newbie-onboarding.mp4",
    startedRecently: "https://pf-vids.s3.amazonaws.com/started-onboarding.mp4",
    experienced: "https://pf-vids.s3.amazonaws.com/experienced-onboarding.mp4",
  };

  return (
    <div className={styles.step}>
      <div className={styles.title}>All set up!</div>
      <div className={styles.subTitle}>{content[userState.level] || ""}</div>
      <div className={styles.content}>
        <video controls>
          <source src={video[userState.level]} type="video/mp4" />
        </video>
        <div className={styles.divider} />

        <div className={styles.text}>
          If you have any questions 👋🏼 feel free to reach out to me (the
          Founder) by email at{" "}
          <a href="mailto:chain@pocketsflow.com">chain@pocketsflow.com</a>. Will
          be happy to help you out!
        </div>
      </div>
    </div>
  );
};

export default Help;
