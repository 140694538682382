import React, { useEffect, useRef, useState } from "react";
import styles from "./Page2.module.scss";
import "../content.scss";
import { FormGroup, RenderHtml, TextInput } from "src/components";
import { ChevronLeft, ChevronRight, X } from "react-feather";
import { formatCurrency } from "src/services/currency";
import { ReviewsComponent, SalesCounter } from "../../components";
import { Rating } from "react-simple-star-rating";
import numeral from "numeral";
import { CtaButton } from "../Page1";
import { BiFullscreen } from "react-icons/bi";
import { getImageUrl } from "../getImageUrl";
import VariantsSelector from "../../components/VariantsSelector";

const Page2 = ({
  product,
  buyProduct,
  currency,
  reviews,
  salesNumber,
  payWantPrice,
  setPayWantPrice,
  user,
  loading,
  demoMode,
  loadingImages,
  variants,
  setSelectedVariant,
  selectedVariant,
}: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fullscreen, setFullscreen] = useState(false);
  const totalReviews = reviews.length;
  const countByRating = [0, 0, 0, 0, 0, 0];
  let sumOfRatings = 0;

  reviews.forEach((review: any) => {
    countByRating[5 - Math.floor(review.rating)]++;
    sumOfRatings += review.rating;
  });

  const showArrows = product.images.length > 1;

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? product.images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === product.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const averageRating = totalReviews > 0 ? sumOfRatings / totalReviews : 0;

  return (
    <div className={styles.page2}>
      {product.images && product.images.length > 0 && fullscreen && (
        <div className={styles.imageContainer + " " + styles.fullscreenImage}>
          <div className={styles.img}>
            {showArrows && (
              <>
                <ChevronLeft
                  className={styles.arrowLeft}
                  onClick={handlePrevious}
                  size={40}
                />
                <ChevronRight
                  className={styles.arrowRight}
                  onClick={handleNext}
                  size={40}
                />
              </>
            )}
            <img
              src={
                loadingImages
                  ? ""
                  : getImageUrl(product.images[currentIndex]?.url, demoMode)
              }
              className={styles.image}
            />
          </div>
          <div
            className={styles.xContainer}
            onClick={() => {
              setFullscreen(false);
            }}
          >
            <X
              size={24}
              onClick={() => {
                setFullscreen(false);
              }}
            />
          </div>
          <div className={styles.overlay} />
        </div>
      )}
      <div
        className={styles.hero}
        style={{
          background: product.colors.find((c: any) => c.id === "heroBackground")
            ?.value,
        }}
      >
        <div className={styles.heroContent}>
          <h1
            style={{
              color: product.colors.find((c: any) => c.id === "title").value,
            }}
          >
            {product.name}
          </h1>
          <h3
            style={{
              color: product.colors.find((c: any) => c.id === "title").value,
            }}
          >
            {product.subtitle}
          </h3>
          <div className={styles.ratingSales}>
            <div className={styles.rating}>
              <Rating
                size={24}
                initialValue={averageRating}
                transition
                allowFraction
                disableFillHover
                allowHover={false}
              />
              <div
                className={styles.revs}
                style={{
                  color: product.colors.find((c: any) => c.id === "title")
                    .value,
                }}
              >
                {numeral(reviews.length).format("0[.]0")} Rating
                {reviews.length > 1 ? "s" : ""}
              </div>
            </div>
            <div
              className={styles.sales}
              style={{
                color: product.colors.find((c: any) => c.id === "title").value,
              }}
            >
              — {numeral(salesNumber).format("0[.]00")} Sales
            </div>
          </div>
          {user && (
            <div
              className={styles.userContainer}
              style={{
                color: product.colors.find((c: any) => c.id === "title").value,
              }}
            >
              Created by{" "}
              <span
                className={styles.user}
                style={{
                  color: product.colors.find((c: any) => c.id === "title")
                    .value,
                }}
                onClick={() => {
                  // new tab
                  // window.open(
                  //   `https://${user.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`,
                  //   "_blank"
                  // );
                  const a = document.createElement("a");
                  a.href = `https://${user.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}`;
                  a.rel = "noopener noreferrer";
                  a.click();
                }}
              >
                {user.firstName && user.lastName
                  ? user.firstName + " " + user.lastName
                  : user.family_name === "" || user.given_name === ""
                  ? user.email
                  : user.given_name + " " + user.family_name}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentBody}>
          <div
            className={styles.left}
            style={{
              color: product.colors.find((c: any) => c.id === "text").value,
            }}
          >
            <RenderHtml html={product.description} />
          </div>
          <div
            className={styles.right}
            style={{
              background: product.colors.find(
                (c: any) => c.id === "cardBackground"
              )?.value,
            }}
          >
            {product.images && product.images.length > 0 && (
              <div className={styles.imageContainer}>
                {showArrows && (
                  <>
                    <ChevronLeft
                      className={styles.arrowLeft}
                      onClick={handlePrevious}
                      size={40}
                    />
                    <ChevronRight
                      className={styles.arrowRight}
                      onClick={handleNext}
                      size={40}
                    />
                  </>
                )}
                <div
                  className={styles.bigImage}
                  onClick={() => {
                    setFullscreen(!fullscreen);
                  }}
                >
                  <BiFullscreen size={16} />
                </div>
                <img
                  src={product.images[currentIndex]?.url}
                  className={styles.image}
                />
              </div>
            )}
            <div className={styles.rightContainer}>
              {product.payWant && (
                <FormGroup
                  color={product.colors.find((c: any) => c.id === "text").value}
                  label="Pay what you want:"
                >
                  <TextInput
                    name="price"
                    type="number"
                    placeholder={
                      product.maxPrice
                        ? `${formatCurrency(product.maxPrice, currency)}+`
                        : "Enter amount"
                    }
                    value={payWantPrice}
                    onChange={(e: any) => {
                      setPayWantPrice(e.target.value);
                    }}
                    isCurrency
                    currency={currency}
                    isPayWant
                    backgroundColor={
                      product.colors.find((c: any) => c.id === "background")
                        .value
                    }
                    textColor={
                      product.colors.find((c: any) => c.id === "text").value
                    }
                    borderColor={
                      product.colors.find((c: any) => c.id === "borders").value
                    }
                  />
                </FormGroup>
              )}
              <CtaButton
                loading={loading}
                payWantPrice={payWantPrice}
                currency={currency}
                product={product}
                buyProduct={buyProduct}
                selectedVariant={selectedVariant}
              />
              {variants && variants.length > 0 && (
                <VariantsSelector
                  variants={variants}
                  setSelectedVariant={setSelectedVariant}
                  currency={currency}
                />
              )}
              {product.showSales && (
                <div className={styles.salesContainer}>
                  <SalesCounter salesNumber={salesNumber} product={product} />
                </div>
              )}
              {product.showReviews && (
                <div className={styles.revsContainer}>
                  <ReviewsComponent reviews={reviews} product={product} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page2;
