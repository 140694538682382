import React, { useEffect, useState } from "react";
import styles from "./OrderSubscription.module.scss";
import { PageTitle } from "src/components/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Button, Card, EmptyState, Modal } from "src/components";
import { formatCurrency } from "src/services/currency";
import { toast } from "react-hot-toast";
import Gravatar from "src/components/Gravatar";
import usePopups from "src/services/usePopups";
import { Download } from "react-feather";

interface CountryInfo {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
}

export type Countries = Record<string, CountryInfo>;

const OrderSubscription: React.FC = () => {
  const [subscriptionCustomer, setSubscriptionCustomer] = useState<any>({});
  const [subscription, setSubscription] = useState<any>({});
  const [customer, setCustomer] = useState<any>();
  const [stripeSubscription, setStripeSubscription] = useState<any>();
  const [invoices, setInvoices] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const { popups, togglePopup } = usePopups([
    "cancelSubscription",
    "refundSubscription",
  ]);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.get("/orders/subscriptions/" + id).then((response) => {
      setSubscriptionCustomer(response.data.subscriptionCustomer);
      setSubscription(response.data.subscription);
      setCustomer(response.data.customer);
      setStripeSubscription(response.data.stripeSubscription);
      setInvoices(response.data.stripeInvoices);
      setLoading(false);
    });
  }, []);

  const [countries, setCountries] = useState<Countries | null>(null);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  return (
    <div className={styles.bigPageContainer}>
      {loading ? (
        <EmptyState loading />
      ) : (
        <>
          <PageTitle
            actions={
              <>
                {stripeSubscription?.status === "active" && (
                  <>
                    <Button
                      variant="secondary"
                      text="Cancel Subscription"
                      onClick={() => togglePopup("cancelSubscription")}
                    />
                    <Button
                      variant="danger"
                      text="Refund"
                      onClick={() => togglePopup("refundSubscription")}
                    />
                    <div className={styles.divider} />
                  </>
                )}
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(subscriptionCustomer._id);
                    toast.success("Subscription ID copied!");
                  }}
                  text={`Copy Id: ${subscriptionCustomer._id}`}
                />
              </>
            }
          >
            Subscription
          </PageTitle>

          <div className={styles.mainContent}>
            <div className={styles.pageContainer}>
              <div className={styles.leftSide}>
                <Card title="Customer">
                  <Link
                    to={`/customers-subscription/${subscriptionCustomer?._id}`}
                    className={styles.customerContainer}
                  >
                    <div className={styles.customerInfo}>
                      <Gravatar email={subscriptionCustomer.email} />
                      <div>
                        <div className={styles.customerName}>
                          {subscriptionCustomer.buyerEmail}
                        </div>
                        <div className={styles.customerEmail}>
                          {dayjs(subscriptionCustomer.createdAt).format(
                            "MMM D, YYYY"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.country}>
                      <span className={styles.emoji}>
                        {countries?.[subscriptionCustomer.country]?.emoji}
                      </span>
                      {countries?.[subscriptionCustomer.country]?.name}
                    </div>
                  </Link>
                </Card>
                <Card title="Subscription">
                  {subscription ? (
                    <Link
                      className={styles.productNameContainer}
                      to={`/subscriptions/${subscription._id}`}
                    >
                      <img
                        src={
                          subscription.thumbnail ||
                          "https://dummyimage.com/1200&text=Subscription+Image"
                        }
                      />
                      <div>
                        <div className={styles.ellipsis}>
                          {subscription.name || "Untitled"}
                        </div>
                        <div className={styles.price}>
                          {formatCurrency(stripeSubscription.plan.amount / 100)} /{" "}
                          {stripeSubscription.plan.interval}
                        </div>
                      </div>
                    </Link>
                  ) : (
                    <div className={`${styles.productNameContainer} ${styles.deleted}`}>
                      <img
                        src="https://dummyimage.com/1200&text=Subscription+Image"
                      />
                      <div>
                        <div className={styles.ellipsis}>
                          Deleted
                        </div>
                        <div className={styles.price}>
                          {formatCurrency(stripeSubscription?.plan?.amount / 100 || 0)} /{" "}
                          {stripeSubscription?.plan?.interval || "month"}
                        </div>
                      </div>
                    </div>
                  )}
                </Card>
              </div>
              <Card title="Subscription Details">
                <div className={styles.orderContainer}>
                  <div>Status</div>
                  <div
                    className={`${styles.subscriptionStatus} ${
                      styles[stripeSubscription?.status]
                    }`}
                  >
                    {stripeSubscription?.status}
                  </div>
                </div>
                <div className={styles.orderContainer}>
                  <div>Start Date</div>
                  <div>
                    {dayjs(
                      new Date(stripeSubscription?.start_date * 1000)
                    ).format("D MMM, YYYY")}
                  </div>
                </div>
                <div className={styles.orderContainer}>
                  <div>Current Period Ends</div>
                  <div>
                    {dayjs(
                      new Date(stripeSubscription?.current_period_end * 1000)
                    ).format("D MMM, YYYY")}
                  </div>
                </div>
                <div className={styles.orderContainer}>
                  <div>Price</div>
                  <div>
                    {formatCurrency(stripeSubscription.plan.amount / 100)} /{" "}
                    {stripeSubscription.plan.interval}
                  </div>
                </div>
              </Card>
            </div>

            <div className={styles.invoicesContainer}>
              <Card title="Invoices">
                <div className={styles.tableContainer}>
                  <table className={styles.invoicesTable}>
                    <thead>
                      <tr>
                        <th>Invoice Number</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Period</th>
                        <th>Date</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoices.map((invoice, index) => (
                        <tr
                          key={index}
                          // onClick={() =>
                          //   window.open(invoice.invoice_pdf, "_blank")
                          // }
                          className={styles.clickableRow}
                        >
                          <td>#{invoice.number}</td>
                          <td>
                            <span
                              className={`${styles.status} ${
                                styles[invoice.status]
                              }`}
                            >
                              {invoice.status}
                            </span>
                          </td>
                          <td>{formatCurrency(invoice.amount_paid / 100)}</td>
                          <td>
                            {dayjs(
                              new Date(
                                invoice.lines.data[0].period.start * 1000
                              )
                            ).format("D MMM")}
                            {" - "}
                            {dayjs(
                              new Date(invoice.lines.data[0].period.end * 1000)
                            ).format("D MMM, YYYY")}
                          </td>
                          <td>
                            {dayjs(invoice.created * 1000).format(
                              "D MMM, YYYY"
                            )}
                          </td>
                          <td>
                            <div className={styles.downloadIcon}>
                              {/* <Download size={16} /> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card>
            </div>
          </div>
        </>
      )}

      {popups.cancelSubscription && (
        <Modal
          onClose={() => togglePopup("cancelSubscription")}
          title="Cancel Subscription"
          footerRightButton1={{
            label: "No, keep subscription",
            onClick: () => togglePopup("cancelSubscription"),
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Yes, cancel subscription",
            onClick: () => {
              toast.promise(
                Axios.post(
                  `/orders/subscriptions/cancel/${stripeSubscription.id}`
                )
                  .then(() => {
                    togglePopup("cancelSubscription");
                    window.location.reload();
                  })
                  .catch((err) => {
                    console.error(err);
                    throw err;
                  }),
                {
                  loading: "Cancelling subscription...",
                  success: "Subscription cancelled",
                  error: "Failed to cancel subscription",
                }
              );
            },
          }}
        >
          <div className={styles.cancelModalText}>
            Are you sure you want to cancel this subscription? This action
            cannot be undone.
          </div>
        </Modal>
      )}

      {popups.refundSubscription && (
        <Modal
          onClose={() => togglePopup("refundSubscription")}
          title="Refund Subscription"
          footerRightButton1={{
            label: "No, keep charges",
            onClick: () => togglePopup("refundSubscription"),
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Yes, refund all charges",
            onClick: () => {
              toast.promise(
                Axios.post(
                  `/orders/subscriptions/refund/${stripeSubscription.id}`
                )
                  .then(() => {
                    togglePopup("refundSubscription");
                    window.location.reload();
                  })
                  .catch((err) => {
                    console.error(err);
                    throw err;
                  }),
                {
                  loading: "Refunding subscription charges...",
                  success: "All subscription charges have been refunded",
                  error: "Failed to refund subscription charges",
                }
              );
            },
          }}
        >
          <div className={styles.cancelModalText}>
            Are you sure you want to refund all charges for this subscription?
            This will:
            <ul className={styles.refundList}>
              <li>Refund all successful payments</li>
              <li>Cancel any pending invoices</li>
              <li>Cancel the subscription</li>
            </ul>
            This action cannot be undone.
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OrderSubscription;
