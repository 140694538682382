import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Axios from "src/services/api";

const VerifiedEmail: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (token) {
      Axios.post("/users/verify-email", {
        token,
      })
        .then(() => {
          toast.success("Email verified!");
          window.location.href = "/profile";
        })
        .catch(() => {
          toast.error("Invalid token");
          window.location.href = "/profile";
        });
    }
  }, [token]);

  return <></>;
};

export default VerifiedEmail;
