import { Button, FormGroup, Modal, TextArea, TextInput } from "src/components";
import styles from "./ShareModal.module.scss";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ShareModal = ({ created, product, onClose, isSubscription }: any) => {
  const navigate = useNavigate();
  const [choice, setChoice] = useState<"product" | "checkout">(
    product?.hasProductPage === "yes" ? "product" : "checkout"
  );
  const onCloseModal = () => {
    onClose
      ? onClose()
      : navigate("/products/" + product._id, { replace: true });
  };
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);

  const prodUrl =
    choice === "product"
      ? product.slug?.length > 0
        ? `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${product.slug}`
        : `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${product._id}`
      : product.slug?.length > 0
      ? product?.hasProductPage !== "yes"
        ? `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/${product.slug}`
        : `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/checkout?productId=${product._id}`
      : `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/checkout?productId=${product._id}`;

  const link = isSubscription
    ? `https://${me.subdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/checkout?subscriptionId=${product._id}`
    : prodUrl;

  const title = isSubscription ? "Share subscription" : "Share product";

  const embedCode = `<script
      src="https://app.pocketsflow.com/pocketsflow-popup.js"
      data-subdomain="${me.subdomain}"
    ></script>`;

  const buttonCodeProduct = `<button
      onClick={() => {
        window.openPocketsflowCheckout({
          type: "product",
          productId: "${product._id}",
        });
      }}
    >
      Buy Product
    </button>`;

  const buttonCodeSubscription = `<button
      onClick={() => {
        window.openPocketsflowCheckout({
          type: "subscription",
          subscriptionId: "${product._id}",
        });
      }}
    >
      Buy Subscription
    </button>`;

  return (
    <Modal onClose={onCloseModal} title={title} noFooter>
      <div className={styles.shareModal}>
        {!isSubscription && (
          <div className={styles.tabs}>
            <div
              className={`${styles.tab} ${
                choice === "product" ? styles.active : ""
              } ${product?.hasProductPage !== "yes" ? styles.disabled : ""}`}
              onClick={() => setChoice("product")}
            >
              Product page
            </div>
            <div
              className={`${styles.tab} ${
                choice === "checkout" ? styles.active : ""
              }`}
              onClick={() => setChoice("checkout")}
            >
              Checkout page
            </div>
          </div>
        )}
        <div className={styles.shareModalContent}>
          <div className={styles.shareModalContentInner}>
            <div className={styles.deleteModalText}>
              Copy the link and share it with the world.
            </div>
            <div className={styles.copyLinkContainer}>
              <TextInput
                type="text"
                value={link}
                onChange={() => {}}
                name="copyLink"
                disabled
              />
              <Button
                variant="secondary"
                text="Copy link"
                onClick={() => {
                  // copy to clipboard
                  navigator.clipboard.writeText(link);
                  toast.success("copied");
                }}
              />
            </div>
          </div>
          <div className={styles.shareModalContentInner}>
            <div className={styles.deleteModalText}>
              Or, embed it on your website.
            </div>
            <div
              className={`${styles.copyLinkContainer} ${styles.embedContainer}`}
            >
              <FormGroup label="index.html script">
                <TextArea
                  value={embedCode}
                  onChange={() => {}}
                  name="embedCode"
                  disabled
                />
              </FormGroup>
              <Button
                variant="secondary"
                text="Copy script"
                onClick={() => {
                  // copy to clipboard
                  navigator.clipboard.writeText(embedCode);
                  toast.success("Script copied");
                }}
              />
            </div>
            <div
              className={`${styles.copyLinkContainer} ${styles.embedContainer}`}
            >
              <FormGroup label="Button to open checkout">
                <TextArea
                  value={
                    isSubscription ? buttonCodeSubscription : buttonCodeProduct
                  }
                  onChange={() => {}}
                  name="embedCode"
                  disabled
                />
              </FormGroup>
              <Button
                variant="secondary"
                text="Copy code"
                onClick={() => {
                  // copy to clipboard
                  navigator.clipboard.writeText(
                    isSubscription ? buttonCodeSubscription : buttonCodeProduct
                  );
                  toast.success("Button copied");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
