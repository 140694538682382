import React, { useEffect, useMemo, useState } from "react";
import { FormGroup, Modal, Select, TextInput } from "src/components";
import styles from "./OnboardingModal.module.scss";
import countries from "i18n-iso-countries";
import { Countries } from "src/pages/Customers/Customers";

interface OnboardingModalProps {
  onClose: () => void;
  onSave: (
    country: string,
    zipCode: string,
    state: string,
    name: string,
    contactEmail: string
  ) => void;
}

// Register English as the language for country names
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// Generate the list of country options
const countryOptions = Object.entries(countries.getNames("en")).map(
  ([code, name]) => ({ value: code, label: name })
);

const OnboardingModal = ({ onClose, onSave }: OnboardingModalProps) => {
  const [country, setCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const [name, setName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [errors, setErrors] = useState<any>({});

  const handleCountryChange = (value: string) => {
    setCountry(value);
  };

  const [countries, setCountries] = useState<Countries>({});

  useEffect(() => {
    import("../../../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  const me = useMemo(
    () => localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!),
    [localStorage.getItem("me")] 
  );

  useEffect(() => {
    if (me) {
      setContactEmail(me.contactEmail);
    }
  }, [me]);

  const validateForm = (): boolean => {
    let formErrors: any = {};

    if (!name) formErrors.name = "Name is required";
    // if (!contactEmail) formErrors.contactEmail = "Support email is required";
    if (!zipCode) formErrors.zipCode = "Zip code is required";
    if (!state) formErrors.state = "Zip code is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  return (
    <Modal
      noX
      noClose
      title="Finish Onboarding"
      footerRightButton2={{
        label: "Save",
        onClick: () => {
          if (!validateForm()) return;
          onSave(country, zipCode, state, name, contactEmail);
        },
      }}
    >
      <div className={styles.modalContent}>
        <FormGroup error={errors.name} label="Name">
          <TextInput
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        {/* <FormGroup error={errors.contactEmail} label="Customer support email">
          <TextInput
            name="contactEmail"
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
          />
        </FormGroup> */}
        <FormGroup label="Country">
          <Select
            options={countryOptions.map(
              (country) =>
                ({
                  ...country,
                  // get emoji flag
                  label:
                    Object.entries(countries).find(
                      ([code, ctry]) => code === country.value
                    )?.[1].emoji +
                    " " +
                    Object.entries(countries).find(
                      ([code, ctry]) => code === country.value
                    )?.[1].name,
                } as any)
            )}
            onChange={handleCountryChange}
            selectedValue={country}
          />
        </FormGroup>
        <FormGroup error={errors.zip} label="Zip code">
          <TextInput
            name="zipCode"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
        </FormGroup>
        <FormGroup error={errors.state} label="State">
          <TextInput
            name="state"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </FormGroup>
      </div>
    </Modal>
  );
};

export default OnboardingModal;
