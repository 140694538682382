import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import lodash from "lodash";
import { defaultSubscription, STEPS } from "./Subscription.consts";
import styles from "./Subscription.module.scss";
import styles2 from "../Product2/steps/ProductPage/ProductPage.module.scss";
import DataLosePrompt from "src/components/DataLosePrompt";
import Axios from "src/services/api";
import { Suspense, useEffect, useMemo, useState } from "react";
import { PageLoader } from "src/components/page-loader";
import usePopups from "src/services/usePopups";
import { FiArrowLeft } from "react-icons/fi";
import { makeCdnUrl } from "src/services/cdn";
import { Button, Modal, SwitchInput } from "src/components";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Terminal, Trash, X } from "react-feather";
import { Checkout } from "../Sales/Checkout";
import toast from "react-hot-toast";
import { IoAlertCircle } from "react-icons/io5";
import { ShareModal } from "../Product2/components";
import {
  CheckoutPageSubscription,
  DefineSubscription,
  PortalSubscription,
  SavingStepSubscription,
} from "./steps";
import { getFile } from "../Product2/Product.utils";
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Subscription = () => {
  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const createdParam = searchParams.get("created");
  const showShareModalParam = searchParams.get("showShareModal");
  const showBankAlertModalParam = searchParams.get("showBankAlertModal");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [bigPreview, setBigPreview] = useState<any>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [mutating, setMutating] = useState<boolean>(false);
  const [product, setProduct] = useState<any>(defaultSubscription);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isYesCheckout, setIsYesCheckout] = useState<boolean>(true);

  const [copiedId, setCopiedId] = useState<boolean>(false);

  const [previousProduct, setPreviousProduct] = useState(defaultSubscription);
  const [savedFiles, setSavedFiles] = useState<{
    file: File | null;
    thumbnail: File | null;
    images: File[] | null;
  }>({
    file: null,
    thumbnail: null,
    images: null,
  });

  const { popups, togglePopup } = usePopups([
    "deleteProduct",
    "bankAlert",
    "share",
    "copy",
  ]);

  useEffect(() => {
    if (!me?.hasSeenSubscriptions) {
      Axios.post("/users/update", {
        hasSeenSubscriptions: true,
      });
    }
  }, [me]);

  const clickNext = (saveDirectly = false) => {
    if (currentStep < STEPS.length && !saveDirectly) {
      setCurrentStep(currentStep + 1);
    } else {
      if (saveDirectly) {
        mutateSubscription(saveDirectly);
      } else {
        if (currentStep < STEPS.length) {
          setCurrentStep(currentStep + 1);
        }
        mutateSubscription(saveDirectly);
      }
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      toast.promise(
        Axios.get(`/subscriptions/${id}`).then(async (res) => {
          setProduct({
            ...res.data,
            thumbnail:
              res.data.thumbnail === "" || res.data.thumbnail === "null"
                ? undefined
                : res.data.thumbnail,
          });

          setPreviousProduct({
            ...res.data,
            thumbnail:
              res.data.thumbnail === "" || res.data.thumbnail === "null"
                ? undefined
                : res.data.thumbnail,
          });

          if (
            res.data.thumbnail &&
            res.data.thumbnail !== "" &&
            res.data.thumbnail !== "null"
          ) {
            setLoadingThumbnail(true);
            getFile(res.data.thumbnail).then((file) => {
              setThumbnail(file);
              setSavedFiles((prevSavedFiles) => ({
                ...prevSavedFiles,
                thumbnail: file,
              }));
              setLoadingThumbnail(false);
            });
          }

          if (res.data.images && res.data.images.length > 0) {
            await Axios.get(`/images/subscription/${id}`).then(async (res) => {
              let prodimgs: any = [];
              const images = res.data;
              await images.forEach(async (image: any, index: number) => {
                if (!image) return;
                if (!image?.url) return;

                await getFile(image.url).then((file) => {
                  if (!file) return;
                  prodimgs[index] = file;
                  setImages(prodimgs);
                  setSavedFiles((prevSavedFiles) => {
                    return {
                      ...prevSavedFiles,
                      images: prodimgs,
                    };
                  });
                });
              });
            });
          }

          if (
            res.data.file &&
            res.data.file !== "" &&
            res.data.file !== "null"
          ) {
            setLoadingFile(true);
            getFile(res.data.file).then((file) => {
              setFile(file);
              setSavedFiles((prevSavedFiles) => ({
                ...prevSavedFiles,
                file,
              }));
              setLoadingFile(false);
            });
          }
          setLoading(false);
        }),
        {
          loading: "Loading subscription...",
          success: <>Subscription loaded</>,
          error: <>Could not load subscription</>,
        }
      );
    }
  }, [id]);

  const validateForm = (saveDirectly = false) => {
    let formErrors: any = {};

    if (!product.name) {
      formErrors.name = "Name is required";
      setCurrentStep(1);
    }

    // price has to be number
    if (isNaN(product.price)) {
      formErrors.price = "Price must be a number";
      setCurrentStep(1);
    }

    // price has to be above 0
    if (product.price <= 0) {
      formErrors.price = "Price must be above 0";
      setCurrentStep(1);
    }

    if (product.hasCallbackURL && !isValidURL(product.callbackUrl)) {
      formErrors.callbackUrl = "Callback URL must be a valid URL";
      setCurrentStep(3);
    }

    if (
      !product.redirectBackUrl ||
      product?.redirectBackUrl?.length === 0 ||
      !isValidURL(product.redirectBackUrl)
    ) {
      formErrors.redirectBackUrl = "Redirect callback URL is not valid";
      setCurrentStep(3);
    }

    // if start date is not today (ignore time)
    // if (
    //   product.startDate &&
    //   product.startDate < new Date(new Date().setHours(0, 0, 0, 0))
    // ) {
    //   formErrors.startDate = "Start date must be today or later";
    // }

    // // if start date is after end date
    // if (
    //   product.startDate &&
    //   product.endDate &&
    //   new Date(product.startDate) > new Date(product.endDate)
    // ) {
    //   formErrors.endDate = "End date must be after start date";
    // }

    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      toast.error(
        <>
          <div className={styles.errorToast}>
            <div className={styles.errorToastTitle}>
              Please fix the following errors:
            </div>
            <ul>
              {Object.keys(formErrors).map((key: string) => (
                <li key={key}>{formErrors[key]}</li>
              ))}
            </ul>
          </div>
        </>,
        {
          duration: 40000,
        }
      );
    }

    return Object.keys(formErrors).length === 0;
  };

  const mutateSubscription = (saveDirectly = false) => {
    if (!validateForm(saveDirectly)) return;

    setMutating(true);
    const formData = new FormData();

    if (file !== savedFiles?.file) formData.append("file", file!);

    if (thumbnail !== savedFiles?.thumbnail)
      formData.append("thumbnail", thumbnail!);

    if (images) {
      for (let i = 0; i < images!.length; i++) {
        formData.append("images", images[i] as File);
      }
    }

    // export const defaultSubscription = {
    //   name: "",
    //   subtitle: "",
    //   price: 0,
    //   payWant: false,
    //   minPrice: undefined,
    //   maxPrice: undefined,
    //   description: "",
    //   file: "",
    //   isFile: true,
    //   url: "",
    //   thumbnail: "",
    //   images: [],
    //   published: true,
    //   checkoutText: "",
    //   thankYouText: "",
    //   refundPolicy: "",
    //   userId: "",
    //   startDate: undefined,
    //   endDate: undefined,
    //   isForever: false,
    //   trialPeriod: 0,
    // };

    let showWarning = false;

    let published = product.published;

    if (!hasBankAccount) {
      published = false;
      showWarning = true;
    }

    formData.append("name", product.name);
    formData.append("subtitle", product.subtitle);
    formData.append("price", (product.price || 0).toString());
    formData.append("description", product.description);
    formData.append("file", product.file);
    formData.append("isFile", product.isFile);
    formData.append("url", product.url);
    formData.append("published", published.toString());
    formData.append("checkoutText", product.checkoutText);
    formData.append("refundPolicy", product.refundPolicy);
    formData.append("userId", product.userId);
    formData.append("startDate", product.startDate?.toString() || "");
    formData.append("endDate", product.endDate?.toString() || "");
    formData.append("callbackUrl", product.callbackUrl?.toString() || "");
    formData.append("hasCallbackUrl", product.hasCallbackUrl);
    formData.append("redirectBackUrl", product.redirectBackUrl?.toString());
    formData.append("frequency", product.frequency?.toString() || "monthly");
    formData.append(
      "isForever",
      (!product.endDate ? true : product.isForever).toString()
    );
    formData.append("trialPeriod", (product.trialPeriod || 0).toString());
    formData.append(
      "hasFirstName",
      product.hasFirstName?.toString() || "false"
    );
    formData.append("hasLastName", product.hasLastName?.toString() || "false");

    if (id) {
      toast.promise(
        Axios.post(`/subscriptions/update/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            setMutating(false);
            setPreviousProduct(product);

            if (showWarning) {
              togglePopup("bankAlert");
            } else {
              togglePopup("share");
            }

            setSavedFiles({
              file,
              thumbnail,
              images,
            });

            setIsCreated(false);
            setCurrentStep(4);
            setMutating(false);
          })
          .catch((err) => {
            console.log(err);
            setMutating(false);
          }),
        {
          loading: "Saving subscription...",
          success: <>Subscription saved</>,
          error: <>Could not save subscription</>,
        }
      );
    } else {
      toast.promise(
        Axios.post("/subscriptions", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            setProduct(res.data);
            setPreviousProduct(res.data);
            if (showWarning) {
              togglePopup("bankAlert");
            }
            setSavedFiles({
              file,
              thumbnail,
              images,
            });
            setIsCreated(true);
            setMutating(false);
            setCurrentStep(4);
          })
          .catch((err) => {
            console.log(err);
            setMutating(false);
          }),
        {
          loading: "Saving product...",
          success: <>Product saved</>,
          error: <>Could not save product</>,
        }
      );
    }
  };

  const [file, setFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [images, setImages] = useState<File[] | null>(null);
  const [errors, setErrors] = useState<any>({});
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingThumbnail, setLoadingThumbnail] = useState(false);

  useEffect(() => {
    if (currentStep === 4) {
      setIsYesCheckout(true);
    }

    if (currentStep !== 4) {
      setIsYesCheckout(false);
    }
  }, [currentStep]);

  const productThumbnail = useMemo(() => {
    if (thumbnail) {
      return URL.createObjectURL(thumbnail);
    }
    return "https://dummyimage.com/1200&text=Product+Thumbnail";
  }, [thumbnail]);

  const productCheckoutText = useMemo(() => {
    return product.checkoutText;
  }, [product.checkoutText]);

  const isEqual =
    lodash.isEqual(product, previousProduct) &&
    lodash.isEqual(savedFiles, {
      file,
      thumbnail,
      images,
    });

  const [stripeAccount, setStripeAccount] = useState<any>(null);

  useEffect(() => {
    Axios.get("/users/stripe-account").then((res) => {
      setStripeAccount(res.data);
    });
  }, []);

  const hasBankAccount = stripeAccount?.external_accounts?.data.length > 0;
  const hasPayPal = !!me?.merchantId && !!me?.merchantIdInPayPal;

  useEffect(() => {
    if (copiedId) {
      setTimeout(() => {
        setCopiedId(false);
      }, 2000);
    }
  }, [copiedId]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <DataLosePrompt isDirty={!isEqual && currentStep !== 4} />
      <div className={styles.productPage}>
        <div className={styles.navbar}>
          <div className={styles.titleContainer}>
            <div
              className={styles.backButton}
              onClick={() => navigate("/products")}
            >
              <FiArrowLeft size={20} color="#3447ef" />
            </div>
            <div className={styles.productTitle}>
              {id && (
                <img
                  src={
                    product.thumbnail
                      ? makeCdnUrl(product.thumbnail)
                      : "https://dummyimage.com/1200&text=Product+Image"
                  }
                />
              )}
              <h1>
                {id
                  ? product.name || "Save Subscription"
                  : "Create Subscription"}
              </h1>
            </div>
            {!isEqual && (
              <div
                title="You have unsaved changes"
                className={styles.isEqualRedDot}
              ></div>
            )}
          </div>
          <div className={styles.actions}>
            {id && product.published && (
              <>
                <Button
                  type="button"
                  variant="tertiary"
                  text="Share or Embed Subscription"
                  onClick={() => togglePopup("share")}
                />
                <div className={styles.divider} />
              </>
            )}
            {currentStep === 2 && (
              <>
                <Button
                  text={
                    <div className={styles2.buttonIcon}>
                      <MdOutlineRemoveRedEye size={18} />
                      Preview checkout page
                    </div>
                  }
                  onClick={() => {
                    setBigPreview("checkout");
                  }}
                />
                <div className={styles.divider} />
              </>
            )}
            <div
              className={
                styles.publishedItem +
                (product.published ? " " + styles.published : "")
              }
            >
              <SwitchInput
                checked={product.published}
                onChange={(checked: any) => {
                  setProduct({ ...product, published: checked });
                }}
                id="published"
                name="published"
              />
              <span>{product.published ? "Published" : "Unpublished"}</span>
            </div>
            <div className={styles.divider} />
            {id && (
              <>
                <Button
                  text={<Trash size={18} />}
                  variant="danger"
                  isIcon
                  onClick={() => togglePopup("deleteProduct")}
                />
                <div className={styles.divider} />
              </>
            )}
            {currentStep === 2 || currentStep === 3 ? (
              <Button
                variant="secondary"
                text="Back"
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
              />
            ) : (
              <Button
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  navigate("/products");
                }}
              />
            )}
            <Button
              text={
                currentStep === 1 || currentStep === 2
                  ? "Next"
                  : id
                  ? "Save subscription"
                  : "Create subscription"
              }
              disabled={mutating || (currentStep === 3 && isEqual)}
              onClick={() => clickNext()}
            />
            {/* Cancel */}
            {/* Save */}
            {/* More actions */}
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.steps}>
            {id && (
              <div className={styles.idContainerBig}>
                <div
                  className={styles.idContainer}
                  data-tooltip-id="subscription-id-tooltip"
                  data-tooltip-content={copiedId ? "Copied!" : "Click to copy"}
                  onClick={() => {
                    navigator.clipboard.writeText(product._id);
                    setCopiedId(true);
                  }}
                >
                  <div className={styles.idIcon}>
                    <Terminal size={9} />
                  </div>
                  <div className={styles.idText}>{product._id}</div>
                </div>
                {copiedId && <div className={styles.copiedId}>Copied!</div>}
              </div>
            )}
            <div className={styles.stepsContainer}>
              {STEPS.map((step, index) => (
                <div
                  key={index}
                  className={`${styles.stepItem} ${
                    currentStep === index + 1 ? styles.stepItemActive : ""
                  }`}
                  onClick={() => setCurrentStep(index + 1)}
                >
                  <div className={styles.iconContainer}>{step.icon}</div>
                  <div className={styles.textContainer}>
                    <h5>{step.name}</h5>
                    <p>{step.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.sideButtonContainer}>
              {!isEqual && (
                <Button
                  variant="primary"
                  text={id ? "Save subscription" : "Create subscription"}
                  onClick={() => clickNext(true)}
                />
              )}
              {id && product.published && (
                <Button
                  variant="secondary"
                  text="Share or Embed Subscription"
                  onClick={() => {
                    togglePopup("share");
                  }}
                />
              )}
            </div>
          </div>
          <div className={styles.form}>
            {/* <div className={styles.savePopup}>
              <div className={styles.savePopupText}>
                {id
                  ? product.name || "Save Subscription"
                  : "Create Subscription"}
              </div>
              {currentStep === 2 || currentStep === 3 ? (
                <Button
                  variant="secondary"
                  text="Back"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                />
              ) : (
                <Button
                  variant="secondary"
                  text="Cancel"
                  onClick={() => {
                    navigate("/subscriptions");
                  }}
                />
              )}
              <Button
                text={
                  currentStep === 1 || currentStep === 2
                    ? "Next"
                    : id
                    ? "Save subscription"
                    : "Create subscription"
                }
                disabled={mutating || (currentStep === 3 && isEqual)}
                onClick={() => clickNext()}
              />
            </div> */}
            <div className={styles.insideForm}>
              {currentStep === 1 ? (
                <DefineSubscription
                  subscription={product}
                  setSubscription={setProduct}
                  file={file}
                  setFile={setFile}
                  thumbnail={thumbnail}
                  setThumbnail={setThumbnail}
                  images={images}
                  setImages={setImages}
                  errors={errors}
                  loadingFile={loadingFile}
                  loadingThumbnail={loadingThumbnail}
                />
              ) : currentStep === 2 ? (
                <CheckoutPageSubscription
                  product={product}
                  setProduct={setProduct}
                />
              ) : currentStep === 3 ? (
                <PortalSubscription
                  subscription={product}
                  setSubscription={setProduct}
                  errors={errors}
                />
              ) : currentStep === 4 ? (
                <SavingStepSubscription
                  subscription={product}
                  isCreated={isCreated}
                  hasBankAccount={hasBankAccount}
                />
              ) : null}
            </div>
          </div>
        </div>
        {bigPreview && (
          <div
            className={styles.bigModalPreviewContainer}
            onClick={() => setBigPreview(undefined)}
          >
            <div
              className={styles.closeContainer}
              onClick={() => setBigPreview(undefined)}
            >
              <X className={styles.modalCloseButton} />
            </div>
            <div
              className={styles.pagesBigPreview}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Suspense fallback={<div>Loading...</div>}>
                {bigPreview === "checkout" ? (
                  <Checkout
                    demoMode
                    isSubscription
                    _user={{
                      ...me,
                    }}
                    id={id}
                    isProdPage
                    prod={{
                      ...product,
                      thumbnail: productThumbnail,
                      checkoutText: productCheckoutText,
                    }}
                  />
                ) : null}
              </Suspense>
            </div>
          </div>
        )}
      </div>
      {popups.deleteProduct && (
        <Modal
          onClose={() => togglePopup("deleteProduct")}
          title="Delete Subscription"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("deleteProduct");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Delete",
            onClick: () => {
              Axios.delete("/subscriptions/" + id)
                .then(() => {
                  togglePopup("deleteProduct");
                  toast.success("Subscription deleted!");
                  navigate("/products");
                })
                .catch((error) => {
                  console.log(error);
                  togglePopup("deleteProduct");
                  toast.error("Failed to delete subscription");
                });
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to delete this subscription?
          </div>
        </Modal>
      )}
      {(showShareModalParam === "true" || popups.share) && (
        <ShareModal
          onClose={() => {
            navigate("/subscriptions/" + product._id, { replace: true });
          }}
          created={createdParam}
          product={product}
          isSubscription
        />
      )}

      {(showBankAlertModalParam === "true" || popups.bankAlert) && (
        <Modal
          onClose={() =>
            navigate("/subscriptions/" + product._id, { replace: true })
          }
          title="Connect a Payment Method"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("bankAlert");
            },
          }}
          footerRightButton2={{
            variant: "danger",
            label: "Connect Bank Account",
            onClick: () => {
              navigate("/profile/payments");
            },
          }}
        >
          <div className={styles.bankModalText}>
            <IoAlertCircle fontSize={24} className={styles.bankModalIcon} />
            To generate revenue from your subscription, you need to connect a
            bank account.
          </div>
        </Modal>
      )}
      <ReactTooltip
        place="top"
        variant="dark"
        float
        id="subscription-id-tooltip"
      />
    </>
  );
};

function isValidURL(url: string) {
  const regex = /^(https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/.*)?$/;
  return regex.test(url);
}

export default Subscription;
