import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  LinkAuthenticationElement,
} from "@stripe/react-stripe-js";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import styles from "./Checkout.module.scss";
import {
  Button,
  ErrorBanner,
  FormGroup,
  Modal,
  TextInput,
} from "src/components";
import Axios from "src/services/api";
import useTaxType from "../tax";
import { formatCurrency } from "src/services/currency";
import { toast } from "react-hot-toast";
import usePopups from "src/services/usePopups";
import "./Checkout.scss";

export default function CheckoutForm({
  product,
  currency,
  country,
  zip,
  state,
  city,
  regionCode,
  ip,

  payWantPrice,

  _upsell,
  user,

  _clientSecret,
  _paymentIntentId,

  discountApplied,
  discount,

  taxPercentage,
  taxCountry,

  selectedVariant,

  webhookMetadata,
  isSubscription,
  subscriptionId,

  isTestMode,
}: any) {
  console.log({ webhookMetadata, isTestMode });
  const currentSubdomain = window.location.hostname.split(".")[0];
  const stripe = useStripe();
  const elements = useElements();
  const { clientSecret, paymentIntentId } = useParams();

  const upsell = product?.upsell;

  const [email, setEmail] = useState("");
  const [vat, setVat] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const affiliate = searchParams.get("a") || "";

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errors, setErrors] = useState<any>({});

  const { popups, togglePopup } = usePopups(["upsell"]);

  const taxType = useTaxType(taxCountry);

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isOriginalButtonVisible, setIsOriginalButtonVisible] = useState(true);

  useEffect(() => {
    if (isSubscription) return;

    if (
      upsell &&
      product?.price === 0 &&
      upsell?.upsellPrice > 0 &&
      !_upsell &&
      !paymentIntentId &&
      !clientSecret
    ) {
      setIsLoading(true);
      Axios.post("/products/update-payment-intent", {
        productId: product?._id,
        country,
        zip,
        state,
        city,
        regionCode,
        currency,
        ip,
        affiliate,

        upsellId: upsell?._id,

        payWantPrice: payWantPrice,

        webhookMetadata,

        taxPercentage: taxPercentage || 0,
        vatNumber: vat || 0,
      }).then((res) => {
        setIsLoading(false);

        const searchParams = new URLSearchParams(window.location.search);
        if (affiliate) searchParams.set("a", affiliate || "");
        searchParams.set("productId", product?._id);

        // construct the new URL
        const newUrl = `${window.location.pathname}/${res.data.clientSecret}/${
          res.data.paymentIntentId
        }?${searchParams.toString()}`;
        navigate(newUrl);
      });
    }
  }, [upsell]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (
      (!clientSecret || !paymentIntentId) &&
      (!_clientSecret || !_paymentIntentId)
    ) {
      return;
    }

    if (isSubscription) {
      Axios.get(
        "/subscriptions/stripe-subscription/" +
          (paymentIntentId || _paymentIntentId) +
          (isTestMode ? "?testMode=true" : "")
      ).then((res) => {
        switch (res.data.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "canceled":
            setMessage("The payment has been canceled.");
            break;
          default:
            setMessage("");
            break;
        }
      });
      return;
    }

    Axios.get(
      "/products/payment-intent/" +
        (paymentIntentId || _paymentIntentId) +
        (isTestMode ? "?testMode=true" : "")
    ).then((res) => {
      switch (res.data.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "canceled":
          setMessage("The payment has been canceled.");
          break;
        default:
          setMessage("");
          break;
      }
    });
  }, [stripe, paymentIntentId, _paymentIntentId]);

  const [processing, setProcessing] = useState(false);

  const handleUpsell = async (acceptsUpsell: boolean) => {
    const actualPaymentIntentId = paymentIntentId || _paymentIntentId;

    let _errors = {};

    // check if first name is empty
    if (product.hasFirstName && !firstName) {
      _errors = { ..._errors, firstName: "First name is required" };
    }

    // check if last name is empty
    if (product.hasLastName && !lastName) {
      _errors = { ..._errors, lastName: "Last name is required" };
    }

    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
      return;
    }

    // buy free
    const buyFree =
      (product?.price === 0 && !product?.payWant && !acceptsUpsell) ||
      (product?.payWant && payWantPrice === 0 && !acceptsUpsell);

    // see if discounted price is 0 or negative
    const discountedPrice = discountApplied
      ? product?.payWant
        ? payWantPrice -
          (discount?.valueType === "percentage"
            ? (discount?.value / 100) * payWantPrice
            : discount?.value)
        : product?.price -
          (discount?.valueType === "percentage"
            ? (discount?.value / 100) * product?.price
            : discount?.value)
      : product?.payWant
      ? payWantPrice
      : product?.price;

    const isFree = buyFree || discountedPrice <= 0;

    setProcessing(true);
    if (acceptsUpsell) {
      // create a new payment intent for the upsell
      if (
        (product?.price === 0 && !product?.payWant) ||
        (payWantPrice === 0 && product?.payWant)
      ) {
      } else {
        await toast.promise(
          Axios.post("/products/update-payment-intent", {
            paymentIntentId: actualPaymentIntentId,
            productId: product?._id,
            country,
            zip,
            state,
            city,
            regionCode,
            currency,
            ip,
            affiliate,

            upsellId: upsell?._id,

            payWantPrice: payWantPrice,

            discountId: discount?._id,

            taxPercentage: taxPercentage || 0,
            vatNumber: vat || 0,

            variantId: selectedVariant?._id,

            firstName,
            lastName,

            webhookMetadata,
          }),
          {
            loading: "Processing...",
            success: "Succeeded!",
            error: "Error processing",
          }
        );
      }
    } else {
      // discounted HERE
      if (
        (selectedVariant || discountApplied || taxPercentage) &&
        !isFree &&
        !isSubscription
      ) {
        await toast.promise(
          Axios.post("/products/update-payment-intent", {
            paymentIntentId: actualPaymentIntentId,
            productId: product?._id,
            country,
            zip,
            state,
            city,
            regionCode,
            currency,
            ip,
            affiliate,

            upsellId: undefined,

            payWantPrice: payWantPrice,

            discountId: discountApplied ? discount?._id : undefined,

            taxPercentage: taxPercentage,
            vatNumber: vat || 0,

            variantId: selectedVariant?._id,

            firstName,
            lastName,

            webhookMetadata,
          }),
          {
            loading: "Processing...",
            success: "Succeeded!",
            error: "Error processing",
          }
        );
      }
    }

    if (
      !email &&
      ((product?.price === 0 && !product?.payWant) ||
        (payWantPrice === 0 && product?.payWant))
    ) {
      setMessage("Please enter your email address.");
      return;
    }

    if (buyFree || discountedPrice <= 0) {
      await toast.promise(
        Axios.post("/products/buy-free", {
          productId: product?._id,
          email,
          country,
          zip,
          state,

          discountId: discount?._id,

          variantId: selectedVariant?._id,

          webhookMetadata,

          firstName,
          lastName,

          isTestMode,
        })
          .then((res) => {
            console.log(res);
            navigate(
              "/checkout/success?redirect_status=succeeded&saleId=" +
                res.data._id
            );
          })
          .catch((err) => {
            console.log(err);
            setMessage(err.response.data.message);
          }),
        {
          loading: "Processing...",
          success: "Succeeded!",
          error: "Error processing",
        }
      );
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    let confirmed: any = {};
    if (isSubscription && !!product.trialPeriod) {
      confirmed = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `https://${currentSubdomain}.${
            process.env.REACT_APP_AUTH0_AUDIENCE
          }/checkout/success?paymentIntentId=${
            paymentIntentId || _paymentIntentId
          }${
            isSubscription
              ? `&isSubscription=true&setupIntentSecret=${paymentIntentId}`
              : ""
          }`,
          payment_method_data: {
            billing_details: {
              name:
                firstName || lastName
                  ? `${firstName || ""} ${lastName || ""}`.trim()
                  : undefined,
              email: email || undefined,
            },
          },
        },
      });
    } else {
      confirmed = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `https://${currentSubdomain}.${
            process.env.REACT_APP_AUTH0_AUDIENCE
          }/checkout/success?paymentIntentId=${
            paymentIntentId || _paymentIntentId
          }${isSubscription ? `&isSubscription=true` : ""}`,
          payment_method_data: {
            billing_details: {
              name:
                firstName || lastName
                  ? `${firstName || ""} ${lastName || ""}`.trim()
                  : undefined,
              email: email || undefined,
            },
          },
        },
      });
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    console.log(confirmed.error);

    setMessage(
      confirmed.error.message! ||
        "An unexpected error occurred. Please try again."
    );

    setIsLoading(false);
    setProcessing(false);

    if (!confirmed.error && upsell?.upsellProduct?._id) {
      togglePopup("upsell");
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (
      ((payWantPrice === 0 && product?.payWant) ||
        (product?.price === 0 && !product?.payWant)) &&
      email === ""
    ) {
      setMessage("Please enter your email address.");
      return;
    }

    if (upsell?.upsellProduct?._id) {
      togglePopup("upsell");
      return;
    } else {
      handleUpsell(false);
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        name:
          firstName || lastName
            ? `${firstName || ""} ${lastName || ""}`.trim()
            : undefined,
        email: email || undefined,
      },
    },
  };

  const taxAmount =
    _clientSecret || !user.taxEnabled
      ? 0
      : parseFloat(product?.payWant ? payWantPrice : product?.price) *
        (taxPercentage / 100);

  const total = _clientSecret
    ? product?.payWant
      ? payWantPrice
      : product?.price
    : parseFloat(product?.payWant ? payWantPrice : product?.price) + taxAmount;

  // const taxAmount = 0;
  // const total = product?.price;

  const prodPrice = useMemo(
    () =>
      discountApplied
        ? product?.payWant
          ? payWantPrice -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * payWantPrice
              : discount?.value)
          : product?.price -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * product?.price
              : discount?.value)
        : product?.payWant
        ? payWantPrice
        : product?.price,
    [discountApplied, discount, product, payWantPrice]
  );

  useEffect(() => {
    if (message) {
      togglePopup("upsell", false);
    }
  }, [message]);

  // Add intersection observer to check if original button is visible
  useEffect(() => {
    if (!submitButtonRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Apply slight delay to make the animation more natural
        if (entry.isIntersecting) {
          // When the button comes into view, hide the floating bar
          setIsOriginalButtonVisible(true);
        } else {
          // When the button goes out of view, show the floating bar
          setIsOriginalButtonVisible(false);
        }
      },
      { threshold: 0.2, rootMargin: "0px 0px 20px 0px" }
    );

    observer.observe(submitButtonRef.current);

    return () => {
      if (submitButtonRef.current) {
        observer.unobserve(submitButtonRef.current);
      }
    };
  }, [submitButtonRef]);

  return (
    <>
      {message && <ErrorBanner message={message}></ErrorBanner>}
      <form id="payment-form" className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.namesForm}>
          {product.hasFirstName && (
            <FormGroup label="First name" error={errors.firstName || ""}>
              <TextInput
                name="firstName"
                type="text"
                placeholder="Jimmy"
                value={firstName}
                onChange={(e: any) => setFirstName(e.target.value)}
                inCheckout
                error={errors.firstName || ""}
              />
            </FormGroup>
          )}
          {product.hasLastName && (
            <FormGroup label="Last name" error={errors.lastName || ""}>
              <TextInput
                name="lastName"
                type="text"
                placeholder="Doe"
                value={lastName}
                onChange={(e: any) => setLastName(e.target.value)}
                inCheckout
                error={errors.lastName || ""}
              />
            </FormGroup>
          )}
        </div>
        <div className={styles.emailInput}>
          {prodPrice > 0 && (_clientSecret || clientSecret) && (
            <LinkAuthenticationElement
              id="link-authentication-element"
              onChange={(e: any) => setEmail(e?.target?.value)}
            />
          )}
        </div>
        {prodPrice > 0 && (_clientSecret || clientSecret) && (
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions as any}
          />
        )}
        {prodPrice <= 0 && (
          <FormGroup label="Enter your email" error={message || ""}>
            <TextInput
              name="email"
              type="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              placeholder="Email"
              error={message || ""}
            />
          </FormGroup>
        )}
        {/* {prodPrice > 0 && (
          <>
            <br />
            <br />
            <FormGroup label="VAT number (optional)">
              <TextInput
                name="vat"
                type="vat"
                value={vat}
                onChange={(e: any) => setVat(e.target.value)}
                placeholder="VAT number / Tax ID"
              />
            </FormGroup>
          </>
        )} */}
        <div className={styles.totalSection}>
          <div className={styles.total}>
            <span>Price</span>
            <div>
              {product?.payWant
                ? formatCurrency(payWantPrice, currency, true)
                : product?.price > 0
                ? formatCurrency(product?.price, currency, true)
                : formatCurrency(0, currency)}
            </div>
          </div>
          {/* {taxPercentage !== undefined && (
            <div className={styles.total}>
              <span>
                {taxType} {product?.price > 0 ? `(${taxPercentage}%)` : ""}
              </span>
              <div>
                {product?.price > 0
                  ? formatCurrency(taxAmount, currency)
                  : formatCurrency(0, currency)}
              </div>
            </div>
          )} */}

          {discountApplied && (
            <>
              <div className={styles.total}>
                <span>
                  Discount (
                  {discount?.valueType === "percentage"
                    ? `${discount?.value}% off`
                    : formatCurrency(discount?.value, currency) + " off"}
                  )
                </span>
                <div>
                  -{" "}
                  {formatCurrency(
                    // Discount Amount
                    discount?.valueType === "percentage"
                      ? product?.payWant
                        ? payWantPrice * (discount?.value / 100)
                        : product?.price * (discount?.value / 100)
                      : discount?.value,
                    currency,
                    true
                  )}
                </div>
              </div>
              <div className={styles.total}>
                <span>Subtotal</span>
                <div>
                  {formatCurrency(
                    (product?.payWant ? payWantPrice : product?.price) -
                      (discount?.valueType === "percentage"
                        ? product?.payWant
                          ? payWantPrice * (discount?.value / 100)
                          : product?.price * (discount?.value / 100)
                        : discount?.value),
                    currency,
                    true
                  )}
                </div>
              </div>
            </>
          )}

          {/*
            total -
            (discount?.valueType === "percentage"
              ? (discount?.value / 100) * total
              : discount?.value)
          */}

          {taxPercentage !== undefined && (
            <div className={styles.total}>
              <span>
                Tax ({taxType} {taxPercentage}%)
              </span>
              <div>
                {formatCurrency(
                  discountApplied
                    ? (product?.payWant
                        ? total -
                          (discount?.valueType === "percentage"
                            ? (discount?.value / 100) * total
                            : discount?.value)
                        : product?.price > 0
                        ? total -
                          (discount?.valueType === "percentage"
                            ? (discount?.value / 100) * total
                            : discount?.value)
                        : 0) -
                        ((product?.payWant ? payWantPrice : product?.price) -
                          (discount?.valueType === "percentage"
                            ? product?.payWant
                              ? payWantPrice * (discount?.value / 100)
                              : product?.price * (discount?.value / 100)
                            : discount?.value))
                    : taxAmount,

                  currency,
                  true
                )}
              </div>
            </div>
          )}

          {!discountApplied && (
            <div className={styles.total}>
              <span>Total</span>
              <div>
                {product?.payWant
                  ? formatCurrency(total, currency, true)
                  : product?.price > 0
                  ? formatCurrency(total, currency, true)
                  : formatCurrency(0, currency)}
              </div>
            </div>
          )}

          {discountApplied && (
            <div className={styles.total}>
              <span>Total</span>
              <div>
                {product?.payWant
                  ? formatCurrency(
                      total -
                        (discount?.valueType === "percentage"
                          ? (discount?.value / 100) * total
                          : discount?.value),
                      currency,
                      true
                    )
                  : total > 0
                  ? formatCurrency(
                      total -
                        (discount?.valueType === "percentage"
                          ? (discount?.value / 100) * total
                          : discount?.value),
                      currency,
                      true
                    )
                  : formatCurrency(0, currency)}
              </div>
            </div>
          )}
        </div>
        <button
          className={styles.submitButton}
          disabled={isLoading || !stripe || !elements}
          id="submit"
          ref={submitButtonRef}
        >
          <span id="button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : prodPrice > 0 ? (
              "Pay " +
              (!discountApplied
                ? product?.payWant
                  ? formatCurrency(total, currency, true)
                  : product?.price > 0
                  ? formatCurrency(total, currency, true)
                  : formatCurrency(0, currency, true)
                : product?.payWant
                ? formatCurrency(
                    total -
                      (discount?.valueType === "percentage"
                        ? (discount?.value / 100) * total
                        : discount?.value),
                    currency,
                    true
                  )
                : product?.price > 0
                ? formatCurrency(
                    total -
                      (discount?.valueType === "percentage"
                        ? (discount?.value / 100) * total
                        : discount?.value),
                    currency,
                    true
                  )
                : formatCurrency(0, currency, true))
            ) : (
              "Get it free"
            )}
          </span>
        </button>
        {/* Show any error or success messages */}
      </form>

      {/* Floating sticky bottom bar for mobile */}
      <div
        className={`${styles.floatingPayBar} ${
          isOriginalButtonVisible ? styles.hidden : ""
        }`}
        aria-hidden={isOriginalButtonVisible}
      >
        <button
          className={styles.submitButton}
          disabled={isLoading || !stripe || !elements}
          onClick={handleSubmit}
          id="floating-submit"
          type="button"
        >
          <span id="floating-button-text">
            {isLoading ? (
              <div className="spinner" id="spinner"></div>
            ) : prodPrice > 0 ? (
              "Pay " +
              (!discountApplied
                ? product?.payWant
                  ? formatCurrency(total, currency, true)
                  : product?.price > 0
                  ? formatCurrency(total, currency, true)
                  : formatCurrency(0, currency, true)
                : product?.payWant
                ? formatCurrency(
                    total -
                      (discount?.valueType === "percentage"
                        ? (discount?.value / 100) * total
                        : discount?.value),
                    currency,
                    true
                  )
                : product?.price > 0
                ? formatCurrency(
                    total -
                      (discount?.valueType === "percentage"
                        ? (discount?.value / 100) * total
                        : discount?.value),
                    currency,
                    true
                  )
                : formatCurrency(0, currency, true))
            ) : (
              "Get it free"
            )}
          </span>
        </button>
      </div>

      {/* {true && ( */}
      {(_upsell || popups.upsell) && (
        <Modal
          isUpsell
          renderInLocalPortal="localPoral"
          noX
          onClose={() => {
            handleUpsell(false);
          }}
          title={upsell?.offer}
          footerRightButton1={{
            label: upsell?.secondaryButtonText || "No, thanks",
            disabled: processing,
            variant: "tertiary",
            onClick: () => {
              handleUpsell(false);
            },
          }}
          footerRightButton2={{
            variant: "primary",
            label: upsell?.primaryButtonText || "Yes, add to my order",
            disabled: processing,
            // @ts-ignore
            onClick: (e: any) => {
              e.preventDefault();

              handleUpsell(true);
            },
          }}
        >
          <div className={styles.upsellModalContent}>
            <div className={styles.upsellDescription}>
              {upsell?.upsellDescription}
            </div>
            <div className={styles.upselllProduct}>
              <div className={styles.upsellImageContainer}>
                <img
                  className={styles.image}
                  src={
                    upsell?.upsellProduct.thumbnail ||
                    "https://dummyimage.com/1200&text=Product+Image"
                  }
                  alt={upsell?.upsellProduct.name}
                />
              </div>
              <div className={styles.upsellTextSide}>
                <div className={styles.upsellTitle}>
                  {upsell?.upsellProduct.name}
                </div>
                <a className={styles.navBrand}>
                  <img
                    src={user.picture}
                    alt="Creator Profile Picture"
                    className={styles.creatorProfilePicture}
                  />
                  <span className={styles.creatorName}>
                    {user.firstName || user.name} {user.lastName}
                  </span>
                </a>
                <div className={styles.upsellProductPrice}>
                  {formatCurrency(
                    product?.upsell
                      ? product?.upsell?.upsellPrice
                      : upsell?.upsellPrice,
                    currency
                  )}
                </div>
              </div>
            </div>
            {prodPrice <= 0 && upsell?.upsellPrice > 0 && !_upsell && (
              <div className={styles.upselllProduct + " " + styles.ch}>
                <PaymentElement
                  id="payment-element"
                  options={paymentElementOptions as any}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
