import React, { useState } from "react";
import { Button, FormGroup, Modal, TextInput } from "src/components";
import Axios from "src/services/api";
import { toast } from "react-hot-toast";
import styles from "./IndiaBusinessModal.module.scss";

interface IndiaBusinessData {
  indiaBusinessName: string;
  indiaBusinessAddress: string;
  indiaBusinessGSTIN: string;
  indiaBusinessPAN: string;
  indiaBusinessEmail: string;
  indiaBusinessPhone: string;
  indiaBusinessWebsite: string;
}

interface IndiaBusinessModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  userData?: any;
}

const IndiaBusinessModal: React.FC<IndiaBusinessModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  userData,
}) => {
  const [formData, setFormData] = useState<IndiaBusinessData>({
    indiaBusinessName: userData?.indiaBusinessName || "",
    indiaBusinessAddress: userData?.indiaBusinessAddress || "",
    indiaBusinessGSTIN: userData?.indiaBusinessGSTIN || "",
    indiaBusinessPAN: userData?.indiaBusinessPAN || "",
    indiaBusinessEmail: userData?.indiaBusinessEmail || "",
    indiaBusinessPhone: userData?.indiaBusinessPhone || "",
    indiaBusinessWebsite: userData?.indiaBusinessWebsite || "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (key: keyof IndiaBusinessData, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validate = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.indiaBusinessName) {
      newErrors.indiaBusinessName = "Business name is required";
    }
    
    if (!formData.indiaBusinessAddress) {
      newErrors.indiaBusinessAddress = "Business address is required";
    }
    
    if (!formData.indiaBusinessGSTIN) {
      newErrors.indiaBusinessGSTIN = "GSTIN is required";
    } else if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(formData.indiaBusinessGSTIN)) {
      newErrors.indiaBusinessGSTIN = "Invalid GSTIN format";
    }
    
    if (!formData.indiaBusinessPAN) {
      newErrors.indiaBusinessPAN = "PAN is required";
    } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.indiaBusinessPAN)) {
      newErrors.indiaBusinessPAN = "Invalid PAN format";
    }
    
    if (!formData.indiaBusinessEmail) {
      newErrors.indiaBusinessEmail = "Business email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.indiaBusinessEmail)) {
      newErrors.indiaBusinessEmail = "Invalid email format";
    }
    
    if (!formData.indiaBusinessPhone) {
      newErrors.indiaBusinessPhone = "Business phone is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    setIsSubmitting(true);
    try {
      await toast.promise(
        Axios.post("/users/request-india-business-approval", formData),
        {
          loading: "Saving business details...",
          success: "Business details saved successfully!",
          error: "Failed to save business details",
        }
      );
      
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error saving business details:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      title="Indian Business Details"
      onClose={onClose}
      footerRightButton1={{
        label: "Cancel",
        onClick: onClose,
      }}
      footerRightButton2={{
        label: isSubmitting ? "Saving..." : "Save",
        onClick: handleSubmit,
        disabled: isSubmitting,
      }}
    >
      <div className={styles.modalContent}>
        <p className={styles.infoText}>
          To comply with Indian regulations, we need additional business details
          before you can connect your bank account.
        </p>

        <FormGroup label="Business Name" error={errors.indiaBusinessName} required>
          <TextInput
            name="indiaBusinessName"
            value={formData.indiaBusinessName}
            onChange={(e) => handleChange("indiaBusinessName", e.target.value)}
            error={errors.indiaBusinessName}
            placeholder="Enter your business name"
          />
        </FormGroup>

        <FormGroup label="Business Address" error={errors.indiaBusinessAddress} required>
          <TextInput
            name="indiaBusinessAddress"
            value={formData.indiaBusinessAddress}
            onChange={(e) => handleChange("indiaBusinessAddress", e.target.value)}
            error={errors.indiaBusinessAddress}
            placeholder="Enter your business address"
          />
        </FormGroup>

        <div className={styles.formGrid}>
          <FormGroup label="GSTIN" error={errors.indiaBusinessGSTIN} required>
            <TextInput
              name="indiaBusinessGSTIN"
              value={formData.indiaBusinessGSTIN}
              onChange={(e) => handleChange("indiaBusinessGSTIN", e.target.value)}
              error={errors.indiaBusinessGSTIN}
              placeholder="e.g. 22AAAAA0000A1Z5"
            />
          </FormGroup>

          <FormGroup label="PAN" error={errors.indiaBusinessPAN} required>
            <TextInput
              name="indiaBusinessPAN"
              value={formData.indiaBusinessPAN}
              onChange={(e) => handleChange("indiaBusinessPAN", e.target.value)}
              error={errors.indiaBusinessPAN}
              placeholder="e.g. AAAAA0000A"
            />
          </FormGroup>
        </div>

        <FormGroup label="Business Email" error={errors.indiaBusinessEmail} required>
          <TextInput
            name="indiaBusinessEmail"
            value={formData.indiaBusinessEmail}
            onChange={(e) => handleChange("indiaBusinessEmail", e.target.value)}
            error={errors.indiaBusinessEmail}
            placeholder="e.g. business@example.com"
            type="email"
          />
        </FormGroup>

        <FormGroup label="Business Phone" error={errors.indiaBusinessPhone} required>
          <TextInput
            name="indiaBusinessPhone"
            value={formData.indiaBusinessPhone}
            onChange={(e) => handleChange("indiaBusinessPhone", e.target.value)}
            error={errors.indiaBusinessPhone}
            placeholder="e.g. +91 9876543210"
          />
        </FormGroup>

        <FormGroup label="Business Website (optional)">
          <TextInput
            name="indiaBusinessWebsite"
            value={formData.indiaBusinessWebsite}
            onChange={(e) => handleChange("indiaBusinessWebsite", e.target.value)}
            placeholder="e.g. https://www.example.com"
          />
        </FormGroup>
      </div>
    </Modal>
  );
};

export default IndiaBusinessModal; 