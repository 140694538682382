import React, { useEffect, useState } from "react";
import styles from "../../Subscription.module.scss";
import {
  CheckInput,
  FormGroup,
  Select,
  SwitchInput,
  TextArea,
  TextInput,
} from "src/components";
import TogglableArea from "src/pages/Product2/steps/DefineProduct/TogglableArea";
import TogglableTabs from "src/pages/Product2/steps/DefineProduct/ToggleTabs";

import SingleUploader from "src/pages/Product2/steps/DefineProduct/SingleUploader";
import DatePickerInput from "src/components/DatePicker";
import usePopups from "src/services/usePopups";

const DefineSubscription = ({
  subscription,
  setSubscription,

  // files:
  file,
  setFile,

  thumbnail,
  setThumbnail,

  errors,
  loadingFile,
  loadingThumbnail,
}: {
  subscription: any;
  setSubscription: React.Dispatch<React.SetStateAction<any>>;
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  id?: string;

  thumbnail: File | null;
  setThumbnail: React.Dispatch<React.SetStateAction<File | null>>;
  images: File[] | null;
  setImages: React.Dispatch<React.SetStateAction<File[] | null>>;

  errors: any;
  loadingFile?: boolean;
  loadingThumbnail?: boolean;
}) => {
  const [prodType, setProdType] = useState<string>(
    subscription.isFile ? "file" : "url"
  );
  const [localLoadingFile, setLocalLoadingFile] = useState(false);
  const [localLoadingThumbnail, setLocalLoadingThumbnail] = useState(false);

  const onChange = (key: string, value: string | boolean) => {
    setSubscription({ ...subscription, [key]: value });
  };

  const onTypeChange = (value: string) => {
    setProdType(value);
    setSubscription({ ...subscription, isFile: value === "file" });
  };

  const onFileChange = (file: File | null) => {
    setFile(file);
  };

  const onThumbnailChange = (file: File | null) => {
    setThumbnail(file);
  };

  return (
    <div className={styles.step}>
      <h1>Define your subscription</h1>
      <p className={styles.desc}>
        Add subscription info, pricing, subscription product, thumbnail and
        images.
      </p>
      <div className={styles.form}>
        <FormGroup label="Name" error={errors.name} required>
          <TextInput
            onChange={(e: any) => onChange("name", e.target.value)}
            value={subscription.name}
            name="name"
            placeholder="Enter subscription name"
            error={errors.name}
          />
        </FormGroup>
        <FormGroup label="Description" error={errors.description}>
          <TextArea
            onChange={(e: any) => onChange("subtitle", e.target.value)}
            value={subscription.subtitle}
            name="subtitle"
            placeholder="Enter subscription description"
            error={errors.subtitle}
          />
        </FormGroup>
        <div id="url">
          <TogglableArea
            title="Subscription type"
            subTitle="Choose the type of your subscription, whether as a file or as a URL (Leave blank if you want to use Webhooks)"
          >
            <FormGroup label="Redirect to a callback URL?" inline reversed>
              <SwitchInput
                name="hasCallbackUrl"
                checked={subscription.hasCallbackUrl}
                onChange={(value) => {
                  onChange("hasCallbackUrl", value);
                }}
                id="hasCallbackUrl"
              />
            </FormGroup>
            {subscription.hasCallbackUrl ? (
              <div className={styles.usernameHolder}>
                <FormGroup
                  label="Callback URL"
                  error={errors.callbackUrl}
                  required
                >
                  <TextInput
                    value={subscription.callbackUrl}
                    name="callbackUrl"
                    onChange={(e: any) =>
                      onChange("callbackUrl", e.target.value)
                    }
                    error={errors.callbackUrl}
                    placeholder="e.g. https://pocketsflow.com"
                  />
                </FormGroup>
                <div className={styles.suffix}>
                  {`?subscriptionId={id}&subscriptionCustomer={subCId}&redirect_status=succeeded`}
                </div>
              </div>
            ) : (
              <>
                <TogglableTabs
                  items={[
                    {
                      id: "file",
                      title: "File",
                      subTitle: "People will download your file",
                    },
                    {
                      id: "url",
                      title: "URL",
                      subTitle: "People will get access to your URL",
                    },
                  ]}
                  onChange={(value: string) => onTypeChange(value)}
                  value={prodType}
                />
                {prodType === "file" ? (
                  <SingleUploader
                    onChange={onFileChange}
                    value={file}
                    text="Maximum file size: 200MB"
                    error={errors.file}
                    loading={loadingFile || localLoadingFile}
                  />
                ) : (
                  <FormGroup label="URL" required error={errors.url}>
                    <TextInput
                      onChange={(e: any) => onChange("url", e.target.value)}
                      value={subscription.url}
                      error={errors.url}
                      name="url"
                      placeholder="Enter URL"
                    />
                  </FormGroup>
                )}
              </>
            )}
          </TogglableArea>
        </div>

        <div id="price">
          <TogglableArea
            title="Pricing"
            subTitle="Choose a recurring price that will be charged to your customers."
          >
            <FormGroup label="Price" error={errors.price} required>
              <TextInput
                onChange={(e: any) => onChange("price", e.target.value)}
                value={subscription.price}
                error={errors.price}
                name="price"
                placeholder="0"
                isCurrency
                type="number"
              />
            </FormGroup>
          </TogglableArea>
        </div>

        <div id="duration">
          <TogglableArea
            title="Trial and Frequency"
            subTitle="Set a trial period and a frequency for your subscription."
          >
            <FormGroup
              label="Free trial days"
              error={errors.trialPeriod}
              required
            >
              <TextInput
                onChange={(e: any) => onChange("trialPeriod", e.target.value)}
                value={subscription.trialPeriod}
                error={errors.trialPeriod}
                name="trialPeriod"
                placeholder="0"
                type="number"
              />
            </FormGroup>
            {/* <div className={styles.formGrid}>
              <FormGroup label="Start date" error={errors.startDate} required>
                <DatePickerInput
                  selectedDate={
                    subscription.startDate
                      ? new Date(subscription.startDate)
                      : null
                  }
                  onChange={(date: any) => onChange("startDate", date)}
                  placeholder="Select start date"
                  error={errors.startDate}
                  minDate={new Date()}
                />
              </FormGroup>
              <FormGroup label="End date" error={errors.endDate}>
                <DatePickerInput
                  selectedDate={
                    subscription.endDate ? new Date(subscription.endDate) : null
                  }
                  onChange={(date: any) => onChange("endDate", date)}
                  placeholder="Select end date"
                  error={errors.endDate}
                  minDate={subscription.startDate}
                />
              </FormGroup>
            </div>
            <div className={styles.selectAll}>
              <CheckInput
                id="forever"
                name="forever"
                checked={subscription.isForever}
                onChange={(value: any) => onChange("isForever", value)}
              />
              <label htmlFor="forever">Ends Forever</label>
            </div> */}
            <FormGroup label="Frequency" error={errors.frequency}>
              <Select
                options={[
                  { label: "Weekly", value: "weekly" },
                  { label: "Monthly", value: "monthly" },
                  { label: "Yearly", value: "yearly" },
                ]}
                onChange={(value: any) => onChange("frequency", value)}
                selectedValue={subscription.frequency}
                error={errors.frequency}
              />
            </FormGroup>
          </TogglableArea>
        </div>

        <TogglableArea
          title="Thumbnail"
          subTitle="Upload a thumbnail of your subscription. Used in your profile page,
          checkout, and emails."
        >
          <SingleUploader
            onChange={onThumbnailChange}
            value={thumbnail}
            text="Supports JPEG, PNG, GIF. Maximum file size: 4MB."
            type="image"
            loading={loadingThumbnail || localLoadingThumbnail}
          />
        </TogglableArea>
      </div>
    </div>
  );
};

export default DefineSubscription;
