import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "src/services/api";
import styles from "./RefundPolicy.module.scss";
import { CreatorNavBar, RenderHtml } from "src/components";
import { PageLoader } from "src/components/page-loader";
import { defaultRefundPolicy } from "src/pages/Refund/Refund.consts";
import { Helmet } from "react-helmet";
import TestModeOverlay from "src/components/PageLayout/TestModeOverlay";

const RefundPolicy = ({ _refundPolicy, _user }: any) => {
  const { prodId } = useParams<{ prodId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  const [refundPolicyText, setRefundPolicyText] = useState<string>("");

  const [creator, setCreator] = useState(_user);

  useEffect(() => {
    if (_refundPolicy) {
      let htmlContent = _refundPolicy;
      const youtubeUrls = htmlContent?.match(/<video src="([^"]*)"><\/video>/g); // Extract <video> tags with YouTube URLs

      youtubeUrls?.forEach((videoTag: any) => {
        const youtubeUrl = videoTag.match(/src="([^"]*)/)[1]; // Extract YouTube URL from the video tag
        const youtubeId = youtubeUrl.split("embed/")[1]; // Extract YouTube video ID

        // Generate iframe equivalent
        const iframeEquivalent = `
          <iframe 
            width="560" 
            height="315" 
            src="https://www.youtube.com/embed/${youtubeId}" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
          </iframe>
        `;

        htmlContent = htmlContent?.replace(videoTag, iframeEquivalent); // Replace specific video tag with iframe
      });

      setRefundPolicyText(htmlContent);
      setLoading(false);
    }
  }, [_refundPolicy]);

  useEffect(() => {
    if (!prodId) return;
    setLoading(true);
    Axios.get(`/refunds/${prodId}`)
      .then((response) => {
        setRefundPolicyText(response.data.policy);
        setName(response.data.name);
        Axios.get(`/users/userId/${response.data.userId}`)
          .then((res) => {
            setCreator(res.data);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [prodId]);

  const footerColor = {
    color: "#000",
  };

  if (loading || !creator)
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );

  return (
    <div className={`${styles.pageContainer}`}>
      {_refundPolicy?.testMode && <TestModeOverlay />}
      {!_refundPolicy && (
        <Helmet>
          <meta charSet="UTF-8" />
          <title>{"Refund Policy"}</title>
          <meta
            name="description"
            content={refundPolicyText?.replace(/<[^>]+>/g, "")}
          />
          {/* <link rel="canonical" href="URL of your main page" /> */}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="robots" content="index, follow" />
          <meta property="og:title" content={"Refund Policy"} />
          <meta
            property="og:description"
            content={refundPolicyText?.replace(/<[^>]+>/g, "")}
          />
          {/* <meta property="og:url" content={} /> */}
          <meta property="og:site_name" content="Pocketsflow" />
          <meta property="og:type" content="Digital Product" />
          <meta name="twitter:card" content="summary" />
          {/* <meta name="twitter:site" content="@yourtwitterusername" /> */}
          <meta name="twitter:title" content={"Refund Policy"} />
          <meta
            name="twitter:description"
            content={refundPolicyText?.replace(/<[^>]+>/g, "").slice(0, 200)}
          />
        </Helmet>
      )}
      <a className={styles.navBrand}>
        <img
          src={creator.picture}
          alt="Creator Profile Picture"
          className={styles.creatorProfilePicture}
        />
        <span className={styles.creatorName}>
          {creator.firstName} {creator.lastName}
        </span>
      </a>
      <div className={styles.content}>
        <h1>Refund Policy</h1>
        <div className={styles.body}>
          <RenderHtml html={refundPolicyText} />
        </div>
      </div>
      <div className={styles.footer} style={footerColor}>
        Powered by{" "}
        <a
          href="https://www.pocketsflow.com/"
          target="_blank"
          style={footerColor}
        >
          Pocketsflow
        </a>
      </div>
    </div>
  );
};

export default RefundPolicy;
