import React, { useEffect, useState } from "react";
import styles from "./Order.module.scss";
import { PageLayout } from "src/components/PageLayout";
import { PageTitle } from "src/components/PageTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import Axios from "src/services/api";
import dayjs from "dayjs";
import { Button, Card, EmptyState, Modal, TextInput } from "src/components";
import { saveAs } from "file-saver";
import axios from "axios";
import { BiBox, BiCalendar, BiMoney } from "react-icons/bi";
import { formatCurrency } from "src/services/currency";
import numeral from "numeral";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-hot-toast";
import Gravatar from "src/components/Gravatar";
import usePopups from "src/services/usePopups";

interface CountryInfo {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
}

export type Countries = Record<string, CountryInfo>;

const Order: React.FC = () => {
  const currentSubdomain = window.location.hostname.split(".")[0];
  const [order, setOrder] = React.useState<any>({});
  const [product, setProduct] = React.useState<any>({});
  const [upsell, setUpsell] = React.useState<any>({});
  const [review, setReview] = React.useState<any>(null);
  const [customer, setCustomer] = React.useState<any>();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [resendLoading, setResendLoading] = React.useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { popups, togglePopup } = usePopups(["issueRefund"]);
  const navigate = useNavigate();

  const me =
    localStorage.getItem("me") && JSON.parse(localStorage.getItem("me")!);

  useEffect(() => {
    Axios.get("/orders/" + id).then((response) => {
      setReview(response.data.review);
      setProduct(response.data.product);
      setOrder(response.data.sale);
      setCustomer(response.data.customer);
      setUpsell(response.data.upsellProduct);
      setLoading(false);
    });
  }, []);

  const [countries, setCountries] = useState<Countries | null>(null);

  useEffect(() => {
    import("../../services/countries.json").then((data) => {
      setCountries(data.default as Countries);
    });
  }, []);

  return (
    <div className={styles.bigPageContainer}>
      {loading ? (
        <EmptyState loading />
      ) : (
        <>
          <PageTitle
            actions={
              <>
                {!order.isRefunded && (
                  <>
                    <Button
                      variant="secondary"
                      text="Issue a refund"
                      onClick={() => togglePopup("issueRefund")}
                    />
                    <div className={styles.divider} />
                  </>
                )}
                <Button
                  variant="secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://${currentSubdomain}.${process.env.REACT_APP_AUTH0_AUDIENCE}/checkout/success?saleId=${order?._id}&redirect_status=succeeded`
                    );
                    toast.success("Link copied to clipboard");
                  }}
                  text="Share online receipt"
                />
                <Button
                  disabled={resendLoading}
                  loading={resendLoading}
                  onClick={() => {
                    setResendLoading(true);
                    Axios.post("/orders/" + id + "/resend-receipt")
                      .then(() => {
                        toast.success("Email receipt sent");
                        setResendLoading(false);
                      })
                      .catch(() => {
                        setResendLoading(false);
                      });
                  }}
                  text="Resend email receipt"
                />
                <Button
                  onClick={() => {
                    // navigate(`/invoice/${id}`);
                    window.open(`/invoice/${id}`, "_blank");
                  }}
                  text="Generate invoice"
                />
              </>
            }
          >
            <div className={styles.OrderTitle}>
              #{order._id.toUpperCase()}
              {/* {!order.isTransferred && order.refundPolicy && (
                <span className={styles.onHold}>Funds on hold</span>
              )} */}
              {order.isRefunded && (
                <span className={styles.onHold}>Refunded</span>
              )}
            </div>
          </PageTitle>
          <div className={styles.pageContainer}>
            <Card title="Customer">
              <Link
                to={"/customers/" + customer._id}
                className={styles.customerContainer}
              >
                <Gravatar email={customer.buyerEmail} size={100} />
                <div className={styles.customerText}>
                  <div className={styles.name}>
                    {customer.firstName} {customer.lastName}
                  </div>
                  <div className={styles.name}>{customer.buyerEmail}</div>
                  <div className={styles.country}>
                    <span className={styles.emoji}>
                      {countries?.[customer.country]?.emoji}
                    </span>
                    {countries?.[customer.country]?.name}
                  </div>
                  {order.vatNumber && (
                    <div className={styles.taxId}>
                      Tax ID: {order.vatNumber}
                    </div>
                  )}
                </div>
              </Link>
              <div className={styles.cardTitle}>Review</div>
              {review ? (
                <div className={styles.reviewContainer}>
                  <div className={styles.rating}>
                    <Rating
                      style={{
                        pointerEvents: "none",
                      }}
                      initialValue={review.rating}
                      size={20}
                      transition
                      disableFillHover
                      allowFraction
                      allowHover={false}
                    />
                  </div>
                  <div className={styles.review}>{review.review}</div>
                </div>
              ) : (
                <div className={styles.noReview}>No review</div>
              )}
            </Card>
            <div>
              <Card title="Product">
                {product ? (
                  <Link
                    className={styles.productNameContainer}
                    to={`/products/${product?._id}`}
                  >
                    <img
                      src={
                        product?.thumbnail ||
                        "https://dummyimage.com/1200&text=Product+Image"
                      }
                    />

                    <div>
                      <div className={styles.ellipsis}>
                        {product?.name || "Untitled"}
                      </div>
                      <div className={styles.price}>
                        {formatCurrency(
                          product?.price || 0,
                          order?.currency?.toUpperCase() || "USD"
                        )}
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div
                    className={`${styles.productNameContainer} ${styles.deleted}`}
                  >
                    <img src="https://dummyimage.com/1200&text=Product+Image" />
                    <div>
                      <div className={styles.ellipsis}>Deleted</div>
                      <div className={styles.price}>
                        {formatCurrency(0, order?.currency || "USD")}
                      </div>
                    </div>
                  </div>
                )}
              </Card>
              <br />
              <br />
              {upsell?._id && (
                <Card title="Upsell product">
                  <Link
                    className={styles.productNameContainer}
                    to={`/products/${upsell?._id}`}
                  >
                    <img
                      src={
                        upsell?.thumbnail ||
                        "https://dummyimage.com/1200&text=Product+Image"
                      }
                    />

                    <div>
                      <div className={styles.ellipsis}>
                        {upsell?.name || "Untitled"}
                      </div>
                      <div className={styles.price}>
                        {formatCurrency(
                          upsell?.price || 0,
                          order?.currency?.toUpperCase() || "USD"
                        )}
                      </div>
                    </div>
                  </Link>
                </Card>
              )}
            </div>
            <Card title="Order summary">
              <div className={styles.orderContainer}>
                <div>Date</div>
                <div>{dayjs(order.createdAt).format("D MMM, HH:mm")}</div>
              </div>
              <div className={styles.orderContainer}>
                <div>Tax %</div>
                <div>
                  {order.taxPercentage ? `${order.taxPercentage}%` : "N/A"}
                </div>
              </div>
              <div className={styles.orderContainer}>
                <div>{order?.paypalFee > 0 ? "PayPal" : ""} Fee</div>
                <div>
                  -{" "}
                  {formatCurrency(
                    order?.fee,
                    order.stripeCurrency?.toUpperCase()
                  )}
                </div>
              </div>
              <div className={styles.orderContainer}>
                <div>Total paid</div>
                <div>
                  {formatCurrency(
                    order.amount / 100,
                    order.currency?.toUpperCase()
                  )}
                </div>
              </div>
              {/* <div className={styles.orderContainer}>
                <div>Tax</div>
                <div>{formatCurrency(order?.taxAmount || 0)}</div>
              </div> */}
            </Card>
          </div>
        </>
      )}
      {popups.issueRefund && (
        <Modal
          onClose={() => togglePopup("issueRefund")}
          title="Issue Refund"
          footerRightButton1={{
            label: "Cancel",
            onClick: () => {
              togglePopup("issueRefund");
            },
          }}
          footerRightButton2={{
            label: "Refund",
            onClick: () => {
              toast.promise(
                Axios.get("/orders/refund/" + id)
                  .then(() => {
                    togglePopup("issueRefund");
                    toast.success("Refund issued!");
                    window.location.reload();
                  })
                  .catch((error) => {
                    console.log(error);
                    togglePopup("issueRefund");
                    toast.error("Failed to issue refund");
                  }),
                {
                  loading: "Issuing refund...",
                  success: "Refund issued!",
                  error: "Failed to issue refund",
                }
              );
            },
          }}
        >
          <div className={styles.deleteModalText}>
            Are you sure you want to issue a refund for this order?
            <br />
            This action cannot be undone.
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Order;
